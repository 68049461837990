/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import styled from "styled-components";


//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { Button, Form, Modal } from 'react-bootstrap'
import { colors } from "../../utils/colors";

// import utilss
import {
  spaceGeneralArrangementTitle,
  spaceRatioText,
  furnitureTabImageTitle,
} from "../../utils/typography";
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";
import Select from 'react-select';
import Cross from "../../Auth/components/icons/Cross";
import SelectIcon from "../../Auth/components/icons/Select";
import PdfIcon from "../../components/icons/PDF";
import RightArrow from "../../Auth/components/icons/RightArrow";
// import Pdf from "react-to-pdf";
import List from "../../Auth/components/icons/List";


const FurnitureTab = ({ furnitureData,furnitures}) => {

  //#region variables region
  const ref = React.createRef();

  const jwt = window.sessionStorage.getItem("jwt") || null;
  const loggedUserId = JSON.parse(window.sessionStorage.getItem("uid") || null);
  
  let existingSelectedFurnitureData = JSON.parse(localStorage.getItem(`furnitureSelectedData`)) || [];
  let existingCheckBox = JSON.parse(localStorage.getItem(`furnitureCheckBox`)) || [];

  const [isShow, setIsShow] = React.useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [itemData, setItemData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [selectedFinishValue, setSelectedFinishValue] = useState([]);
  const [selectedFrametype, setSelectedFrametype] = useState([]);

  const [selectedFrameSize, setSelectedFrameSize] = useState([]);
  const [selectedFurnitureFabric, setSelectedFurnitureFabric] = useState([]);
  const [frameFinish, setFrameFinish] = useState([]);
  const [baseFrametype, setBaseFremetype] = useState([]);
  const [furnitureSize, setFurnitureSize] = useState([]);
  const [furnitureFabric, setFurnitureFabric] = useState([]);
  const [data, setData] = useState([]);

  const [manuFactureName, setMauFactureName] = useState([]);
  const [checkboxShow, setCheckBoxShow] = useState(() => { return JSON.parse(localStorage.getItem(`furnitureCheckBox`)) || [] });
  const [selected, setSelected] = useState(() => { return JSON.parse(localStorage.getItem(`furnitureSelectedData`)) || [] });
  const [ , setStoreData] = useState();
  const [cardId, setCardId] = useState(0);
  const [roomBoard, setRoomBoard] = useState(false);
  const [furniturerooms, setfurnitureRooms] = useState();
  const [isViewRoomSelected, setIsViewRoomSelected] = useState(false);

  const [hiddenButton, setHiddenButton] = useState(false);
  const [isfurnitureRoomsNew, setIsFurnitureRoomsNew] = useState(false);

  //#endregion

  //#region useEffect region

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem('furnitureSelectedData'))
    data?.furnitures?.filter((elem) => {
      return id?.some((ele) => {
        return ele[0]?.id === elem?.id
      });
    });
  }, [selected])

  useEffect(()=>{
    setData(furnitureData)
    getRoomData(furnitureData)
  },[furnitureData,roomBoard])

  useEffect(() => {
    setStoreData(JSON.parse(localStorage.getItem(`furnitureSelectedData`)) || [])
  }, [cardId])

  //#endregion


  //#region methods region

  const getRoomData = async (furnitureData) => {
    setIsViewRoomSelected(false)
    fetch(`${config.strapiUrl}/furniture-rooms`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${jwt}` },
    })
      .then((response) => response.json())
      .then((resData) => {
        
        let userExist = resData.find((user) => user.users_permissions_user.id === loggedUserId);
        if (userExist) {
          const previousSelectedFurniture = userExist.furniture_options.map((fur_opt) => {
            const keys = Object.keys(fur_opt)
            return keys.map((key) => {
              return {
                id: parseInt(key),
                frameFinish: fur_opt[key].finish ? [fur_opt[key].finish] : [],
                frameType: fur_opt[key].type ? [fur_opt[key].type] : [],
                frameSize: fur_opt[key].size ? [fur_opt[key].size] : [],
                furnitureFabric: fur_opt[key].febric ? [fur_opt[key].febric] : [],
              }
            })
          }).flat(2)
          localStorage.setItem("furnitureSelectedData", JSON.stringify(previousSelectedFurniture))
          let result = furnitureData?.furnitures?.filter((item => userExist.furnitures.some((fu) => fu.id === item.id))).map(item => item.id)
          let filterFurnituresData = userExist.furnitures?.filter((item => furnitureData?.furnitures.some((fu) => fu.id === item.id)));
          let checkedFurnitureId = userExist?.furnitures.map(item => item.id)
          if (checkedFurnitureId.length > 0) {
            localStorage.setItem("furnitureCheckBox", JSON.stringify(checkedFurnitureId))
          }
          if (result && filterFurnituresData) {
            setCheckBoxShow(result)
          }
          if (filterFurnituresData && filterFurnituresData.length > 0) {
            setIsViewRoomSelected(true)
          }       
          setfurnitureRooms(userExist);
        }
        else {
          setIsFurnitureRoomsNew(true)
        }
      })
  }


  const handleclick = async (item) => {
    if(checkboxShow.includes(item.id)) {
      setIsEdit(true)
    }
    setCardId(item.id)
    await fetch(`${config.strapiUrl}/furnitures/${item.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data?.furniture_frame_finishes?.map((item) => {
          setFrameFinish(finishFrame => [...finishFrame, { value: item.id, label: item.frame_finish, image: item?.image?.url }])
        })
        data?.furniture_base_frame_types?.map((item) => {
          setBaseFremetype(frameType => [...frameType, { value: item.id, label: item.base_frame_type, image: item?.image?.url }])
        })
        data?.furniture_sizes?.map((item) => {
          setFurnitureSize(sizeFrame => [...sizeFrame, { value: item.id, label: item.size }])
        })
        data?.furniture_fabric_suggestions?.map((item) => {
          setFurnitureFabric(fabricSuggestion => [...fabricSuggestion, { value: item.id, label: item.suggestion,image: item?.furniture_fabric_image?.url }])
        })
        setMauFactureName(data?.furniture_manufacturer?.name);
        setIsShow(!false)
        setItemData(item.name)
        setImageUrl(item.image.url)

      })
      existingSelectedFurnitureData?.map((elem) => {
      if (elem.id == item.id) {
        setSelectedFinishValue(elem.frameFinish)
        setSelectedFrametype(elem.frameType)
        setSelectedFrameSize(elem.frameSize)
        setSelectedFurnitureFabric(elem.furnitureFabric)
        // setFurnitureBaseFrame(item?.frameImage?.url);
      }
    })
  }


  const onFrameFinish = (e) => {
    setSelectedFinishValue([e])
  }


  const onFrameType = (e) => {
    setSelectedFrametype([e])

  }


  const onFrameSize = (e) => {
    setSelectedFrameSize([e]);
  }

  const onFurnitureFabric = (e) => {
    setSelectedFurnitureFabric([e])
  }

  const handleclose = () => {
    setFurnitureSize([]);
    setBaseFremetype([]);
    setFrameFinish([]);
    setSelectedFinishValue([]);
    setSelectedFrameSize([]);
    setFurnitureFabric([]);
    setSelectedFrametype([]);
    setSelectedFurnitureFabric([]);
    return setIsShow(!true)
  }


  const handleClickCheckBox = (id) => {

    if (existingCheckBox.includes(id)) {
      setCheckBoxShow(checkboxShow.filter((elem) => elem !== id))
      setSelected(selected.filter((elem) => {
        return elem.id !== id
      }))

      let removeSelectedFurniture=existingSelectedFurnitureData.filter((elem) => elem.id !== id)
         localStorage.setItem(`furnitureSelectedData`, JSON.stringify(removeSelectedFurniture))
         localStorage.setItem(`furnitureCheckBox`, JSON.stringify(existingCheckBox
                    .filter(item => item !== id)))
      let removeFur=existingCheckBox.filter((elem) => elem !== id)
      handleSubmitupdate(removeFur)
    } 

    setFurnitureSize([]);
    setBaseFremetype([]);
    setFrameFinish([]);
    setSelectedFinishValue([]);
    setSelectedFrameSize([]);
    setSelectedFrametype([]);
    setFurnitureFabric([]);
    setSelectedFurnitureFabric([]);
  }

  const handleSubmitupdate = (removeFur) => {    
    // setSelected(selected?.map((elem) => {
    //   if (elem.id === cardId) {
    //     return { ...elem, frameFinish: selectedFinishValue, frameType: selectedFrametype, frameSize: selectedFrameSize, furnitureFabric: selectedFurnitureFabric }
    //   }
    //   return elem
    // }))
    // localStorage.setItem(`furnitureSelectedData`, JSON.stringify(selected.map((elem) => {
    //   if (elem.id === cardId) {
    //     return { ...elem, frameFinish: selectedFinishValue, frameType: selectedFrametype, frameSize: selectedFrameSize, furnitureFabric: selectedFurnitureFabric }
    //   }
    //   return elem
    // })))

    const furnitureSelectedData = JSON.parse(localStorage.getItem('furnitureSelectedData')) || 0;
    const furnitures = JSON.parse(localStorage.getItem('furnitureCheckBox')) || 0;
    const room = [];
    let tst;
    furnitureSelectedData.forEach(element => {
      tst = {
        [element.id]: {
          finish: selectedFinishValue.length > 0 && element.id === cardId ? selectedFinishValue[0] : element?.frameFinish[0],
          size: selectedFrameSize.length > 0 && element.id === cardId ? selectedFrameSize[0] : element?.frameSize[0],
          type: selectedFrametype.length > 0 && element.id === cardId ? selectedFrametype[0] : element?.frameType[0],
          febric: selectedFurnitureFabric.length > 0 && element.id === cardId ? selectedFurnitureFabric[0] : element?.furnitureFabric[0]
        }
      };
      room.push(tst);
    });
    const furnitureAllData = JSON.parse(localStorage.getItem('recentlyViewedSpaces'));
    const object = {
      name: 'furniture room',
      furnitures: removeFur.length > 0 ? removeFur : furnitures,
      furniture_options: room,
      space_variant: data?.space_variant_id,
      space_type: data?.space_type_id,
      users_permissions_user: furnitureAllData?.uid
    }
    if (furniturerooms) {
      fetch(`${config.strapiUrl}/furniture-rooms/${furniturerooms?.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${jwt}` },
        body: JSON.stringify(object),
      }).then((response) => {
        if (response?.status === 200) {
          getRoomData(furnitureData);
        
        }
      })
    }
    setFurnitureSize([]);
    setBaseFremetype([]);
    setFrameFinish([]);
    setSelectedFinishValue([]);
    setSelectedFrameSize([]);
    setFurnitureFabric([]);
    setSelectedFrametype([]);
    setSelectedFurnitureFabric([]);
    setIsEdit(false)
    setIsShow(!true)
  }



  const handleSubmit = () => {

    if (selectedFrameSize.length > 0 || selectedFrametype.length > 0 || selectedFinishValue.length > 0 || selectedFurnitureFabric.length > 0) {
      if (selected.length === 0 || selected.some((elem) => elem.id !== cardId)) {
        setSelected([...selected, { frameFinish: selectedFinishValue, frameType: selectedFrametype, frameSize: selectedFrameSize, furnitureFabric: selectedFurnitureFabric, id: cardId }])
        localStorage.setItem(`furnitureSelectedData`, JSON.stringify([...existingSelectedFurnitureData, { frameFinish: selectedFinishValue, frameType: selectedFrametype, frameSize: selectedFrameSize, furnitureFabric: selectedFurnitureFabric, id: cardId }]))
      } else {
        setSelected(selected.map((elem) => {
          if (elem.id === cardId) {
            return { ...elem, frameFinish: selectedFinishValue, frameType: selectedFrametype, frameSize: selectedFrameSize, furnitureFabric: selectedFurnitureFabric }
          }
          return elem
        }))
        localStorage.setItem(`furnitureSelectedData`, JSON.stringify(selected.map((elem) => {
          if (elem.id === cardId) {
            return { ...elem, frameFinish: selectedFinishValue, frameType: selectedFrametype, frameSize: selectedFrameSize, furnitureFabric: selectedFurnitureFabric }
          }
          return elem
        })))
      }


      // if (!checkboxShow.includes(cardId)) {
      //   checkboxShow.push(cardId)
      // }
      if(existingCheckBox.length===0){
        localStorage.setItem(`furnitureCheckBox`, JSON.stringify([cardId]))
      }
      else{
        if(!existingCheckBox.includes(cardId)){
          let removeExist = existingCheckBox.concat(cardId);
          localStorage.setItem(`furnitureCheckBox`, JSON.stringify(removeExist))
        }
      }
     
    
      checkboxShow.push(cardId)
      setFurnitureSize([]);
      setBaseFremetype([]);
      setFrameFinish([]);
      setSelectedFinishValue([]);
      setSelectedFrameSize([]);
      setFurnitureFabric([]);
      setSelectedFrametype([]);
      setSelectedFurnitureFabric([]);

      if (isEdit) {
        handleAddRoomBoard()
      }
    } else {
      setCheckBoxShow(checkboxShow.filter((elem) => elem !== cardId))
      localStorage.setItem(`furnitureCheckBox`, JSON.stringify(checkboxShow.filter((elem) => elem !== cardId)))
      setSelected(selected.filter((elem) => elem.id !== cardId))
      localStorage.setItem(`furnitureSelectedData`, JSON.stringify(selected.filter((elem) => elem.id !== cardId)))
      setFurnitureSize([]);
      setBaseFremetype([]);
      setFrameFinish([]);
      setSelectedFinishValue([]);
      setSelectedFrameSize([]);
      setFurnitureFabric([]);
      setSelectedFrametype([]);
      setSelectedFurnitureFabric([]);
    }
    setIsShow(!true)
  }


  const handleResetData = () => {
    const removeId = furnitures.filter((item)=>selected.some((select)=>select.id===item.id))
    const newArr = JSON.parse(localStorage.getItem("furnitureCheckBox")).filter(element => element !== removeId);
    localStorage.setItem(`furnitureCheckBox`, JSON.stringify([newArr]))
    localStorage.setItem(`furnitureSelectedData`, JSON.stringify([]))
    setSelected([])
    setCheckBoxShow([])
  }


  const handleAddRoomBoard = () => {
    const furnitureData = JSON.parse(localStorage.getItem('furnitureSelectedData')) || 0;
    const furnitures = JSON.parse(localStorage.getItem('furnitureCheckBox')) || 0;
    const room = [];
    let tst;
    furnitureData.forEach(element => {
      tst = {
        [element.id]: {
          finish: element?.frameFinish[0],
          size: element?.frameSize[0],
          type: element?.frameType[0],
          febric: element?.furnitureFabric[0]
        }
      };
      room.push(tst);
    });
    const furnitureAllData = JSON.parse(localStorage.getItem('recentlyViewedSpaces'));
    const object = {
      name: 'furniture room',
      furnitures: furnitures,
      furniture_options: room,
      space_variant: data?.space_variant_id,
      space_type: data?.space_type_id,
      users_permissions_user: furnitureAllData?.uid
    }
    if (!isfurnitureRoomsNew) {
      fetch(`${config.strapiUrl}/furniture-rooms/${furniturerooms?.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${jwt}` },
        body: JSON.stringify(object),
      }).then((response) => {
        if (response?.status === 200) {
          setIsViewRoomSelected(true)
          getRoomData();
          if (!isEdit) {
            setModelOpen(true)
          } else {
            setIsEdit(false);
          }
        }
      })
    } else {
      fetch(`${config.strapiUrl}/furniture-rooms`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${jwt}` },
        body: JSON.stringify(object),
      }).then((response) => {
        if (response?.status === 200) {
          getRoomData();
          setModelOpen(true)
        }
      })
    }
  }


  const handleModelClose = () => {
    setModelOpen(false)
  }


  const handleRoomBoard = () => {
    setRoomBoard(true)
    setModelOpen(false)
  }


  const handleUpdateOption = async (item) => {
    setCardId(item.id)
    await fetch(`${config.strapiUrl}/furnitures/${item.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data?.furniture_frame_finishes.map((item) => {
          setFrameFinish(finishFrame => [...finishFrame, { value: item.id, label: item.frame_finish, image: item?.image?.url }])
        })
        data?.furniture_base_frame_types.map((item) => {
          setBaseFremetype(frameType => [...frameType, { value: item.id, label: item.base_frame_type, image: item?.image?.url }])
        })
        data?.furniture_sizes.map((item) => {
          setFurnitureSize(sizeFrame => [...sizeFrame, { value: item.id, label: item.size, image: item?.image?.url }])
        })
        data?.furniture_fabric_suggestions?.map((item) => {
          setFurnitureFabric(fabricSuggestion => [...fabricSuggestion, { value: item.id, label: item.suggestion,image: item?.furniture_fabric_image?.url }])
        })
        setMauFactureName(data?.furniture_manufacturer?.name);
        setIsShow(!false)
        setIsEdit(true);
        setItemData(item.name)
        setImageUrl(item.image.url)
      })
      existingSelectedFurnitureData?.map((elem) => {
      if (elem.id == item.id) {
        setSelectedFinishValue(elem.frameFinish)
        setSelectedFrametype(elem.frameType)
        setSelectedFrameSize(elem.frameSize)
        setSelectedFurnitureFabric(elem.furnitureFabric)

      }
    })
    setSelected(JSON.parse(localStorage.getItem(`furnitureSelectedData`)))
  }

  const handlePdf = () => {
    setHiddenButton(true)
    setTimeout(() => {
      setHiddenButton(false)
    }, 1)
  }

  //#endregion


  return (
    // furniture descprition start
    <Container className="my-4 mb-5">
      <Row>
        <Col xs={12} md={5}>
          <div style={{ minHeight: "39px", marginBottom: "20px" }}>
            <h2 style={spaceGeneralArrangementTitle}>Furniture</h2>
          </div>
          {data?.furniture_image && (
            <ImageStyled src={config.strapiUrl + data?.furniture_image?.url} />
          )}
          <p
            style={spaceRatioText}
            dangerouslySetInnerHTML={{ __html: data?.furniture_description }}
          />
          <h2 style={spaceGeneralArrangementTitle}>Coded furniture plan</h2>
          {data?.coded_furniture_pack && (
            <ImageStyled src={config.strapiUrl + data?.coded_furniture_pack?.url} />
          )}
        </Col>
        {/* // furniture descprition end */}

        {/* Available Furniture option Start */}
        {
          !roomBoard ? <Col xs={12} md={7}>
            {/* <div style={{ minHeight: "39px", marginBottom: "20px" }}>
              <h2 style={spaceGeneralArrangementTitle}>Available Furniture option</h2>
              {furnitures.filter((item)=>selected.some((select)=>select.id===item.id)).length > 0 &&
              <ButtonGroup>
                <Button  onClick={handleRoomBoard}>View room board <span className="roundIcon"><RightArrow /></span></Button>
              </ButtonGroup>
            }
            </div> */}
            <ProductModule>

            <div className="d-flex align-items-center justify-content-between gap-2" style={{ minHeight: "39px", marginBottom: "20px" }}>
              <h2 style={spaceGeneralArrangementTitle} className="mb-0">Available Furniture option</h2>

              {/* {furnitures?.filter((item)=>selected.some((select)=>select.id===item.id)).length > 0 && */}
              {isViewRoomSelected &&
                <ButtonGroup style={{background:"transparent", border:"none"}}>
                  <Button 
                  style={{
                    padding:"8px 15px", 
                    border:"1px solid #000", 
                    borderRadius:"5px", 
                    backgroundColor:"#e60050", 
                    color:"#ffff", 
                    fontWeight:"600", 
                    fontSize:"14px", 
                    position:"relative", 
                    maxWidth:"350px", 
                    textAlign:"center" 
                  }}
                  onClick={handleRoomBoard}>View room board <span className="roundIcon"><RightArrow /></span></Button>
                </ButtonGroup>
              }
              
            </div>
            </ProductModule>
            <p style={spaceRatioText}>Available Furniture option</p>
            <Row>
              {data?.furnitures?.map((item) => {
                // let data=checkboxShow.includes(item.id);
              return<><Col key={item.id} xs={12} md={4} style={{ marginBottom: "10px" }}>
                  {
                    
                    // selected.some((elem) => elem.id == item.id) ?
                   <>   <div onClick={() => handleClickCheckBox(item.id)}>
                        <ProductImageBox>
                        <span className={checkboxShow?.includes(item.id) ? "checkSelection active" : "checkSelection"}>
                          {checkboxShow.includes(item.id)  && <SelectIcon />}
                            </span>
                           </ProductImageBox>
                      </div>
                             <div onClick={() => handleclick(item)}>
                              {item?.image && (
                                <ImageStyled src={config.strapiUrl + item?.image?.url} style={{
                                  border: "1px solid #eee", borderRadius: "5px", overflow:
                                    "hidden"
                                }} />
                              )}
                    <p style={furnitureTabImageTitle}>{item.name}</p>
                  </div>     </>  }    
                </Col></>
             
                    })}
            </Row>
            {/* {furnitures?.filter((item)=>selected.some((select)=>select.id===item.id)).length > 0 && */}
            {furnitures?.filter((item)=>checkboxShow.includes(item.id)).length>0 &&

              <ButtonGroup>
                <Button on onClick={handleResetData}>Reset all option</Button>
                <Button onClick={handleAddRoomBoard}>Add to room board <span className="roundIcon"><RightArrow /></span></Button>
              </ButtonGroup>
            }

            {/* Available Furniture option End */}

            {/* Room Furniture option Start */}
          </Col> :
            <Col xs={12} md={7}>
              <ProductModule>
                <div className="d-flex align-items-center justify-content-between gap-2" style={{ marginBottom: "20px" }}>
                  <h2 style={spaceGeneralArrangementTitle} className="mb-0">Room Furniture option</h2>
                  <Button onClick={() => setRoomBoard(false)}>Return to All available options <span className="roundIcon"><RightArrow /></span></Button>
                </div>
                <div>
                  <div ref={ref}>
                 {furniturerooms?.furnitures.filter((item=>furnitureData?.furnitures.some((fu)=>fu.id===item.id))).map((items) => (
                   <>
                        <Row>
                          <p style={{
                            textAlign: "left",
                            color: "rgb(85, 20, 180)",
                            fontWeight: 600,
                            fontSize: "18px",
                            marginBottom: "10px",
                          }}>{items.name}</p>
                          {furniturerooms.furniture_options.map((x) => {
                            if (x[items.id]) {
                            let url=items?.furnitureFrameType.find((selected)=>selected.furniture_base_frame_type.id===x[items.id]?.type?.value);
                              return (
                                <>
                                  <Col xs={12} md={4} style={{ marginBottom: "10px" }}>
                                      {url?.Image?.url ? (
                                        <>
                                          <ImageStyled src={config.strapiUrl + url?.Image?.url} style={{
                                          border: "1px solid #eee", borderRadius: "5px", overflow:
                                          "hidden", height: "100%",
                                          objectFit: "cover"
                                        }} />
                                        </>
                                      ) : (
                                        <>
                                          <ImageStyled src={config.strapiUrl + items?.image.url} style={{
                                          border: "1px solid #eee", borderRadius: "5px", overflow:
                                          "hidden", height: "100%",
                                          objectFit: "cover"
                                          }} />
                                        </>
                                      )}
                                  </Col>

                                  <Col xs={12} md={4} style={{ marginBottom: "10px" }}>
                                    {x[items.id]?.febric?.label && (
                                      < ImageStyled src={config.strapiUrl + x[items.id]?.febric?.image} style={{
                                      border: "1px solid #eee", borderRadius: "5px", overflow:
                                        "hidden", height: "100px",
                                      objectFit: "cover"
                                    }} />  
                                    )}
                                    <h6 className="productDetailslist">{x[items.id]?.febric?.label ? `Fabric Suggestion - ${x[items.id]?.febric?.label}` :""} </h6>
                                    <h6 className="productDetailslist">{x[items.id]?.size?.label ? `Size - ${x[items.id]?.size?.label}` : ""}</h6>
                                    <h6 className="productDetailslist">{x[items.id]?.type?.label ? `Base/Frame Finish - ${x[items.id]?.type?.label}`: ""}</h6>
                                    <Button style={{padding:"4px 8px"}} hidden={hiddenButton} onClick={() => handleUpdateOption(items)}>Edit options <List /></Button>
                                  </Col>

                                  <Col xs={12} md={4} style={{ marginBottom: "10px" }}>
                                    {x[items.id]?.finish?.label && (
                                      < ImageStyled src={config.strapiUrl + x[items.id]?.finish?.image} style={{
                                        border: "1px solid #eee", borderRadius: "5px", overflow:
                                          "hidden", height: "100px",
                                        objectFit: "cover"
                                      }} />
                                    )}
                                    <h6 className="productDetailslist">{x[items.id]?.finish?.label ? `Frame Finish - ${x[items.id]?.finish?.label}` :""} </h6>
                                  </Col>
                                </>
                              )
                            }
                          })}
                          
                        </Row>
                      </>
                    ))}
                  </div>
                </div>
                <div >

                  {/* <Pdf targetRef={ref} filename="Furniture-details.pdf">
                    {({ toPdf }) => <Button
                      marginRight="15px"
                      bgColor={colors.secondary}
                      width="250px"
                      height="44px"
                      onClick={() => { handlePdf(); toPdf(); }}
                      downloadBtn={false}
                      style={{
                        padding: "8px 15px",
                        border: "1px solid #000",
                        borderRadius: "5px",
                        backgroundColor: "#e60050",
                        color: "#ffffff",
                        fontWeight: 600,
                        fontSize: "14px",
                        position: "relative",
                        maxWidth: "350px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        gap: "0",
                        margin: "25px auto",
                      }}
                    >
                      <PdfIcon />
                      Download room furniture board
                    </Button>}
                  </Pdf> */}
                </div>
              </ProductModule>
            </Col>
        }
      </Row>
      {/* Room Furniture option End */}


      {/* Model Open Start */}
      <Modal show={isShow} className="productDetailsModel" style={{ padding: "0" }}>
        <Modelheader>
          <div>
            {manuFactureName && <h6>{manuFactureName}</h6>}
            <p>{itemData}</p>
          </div>
          <Button onClick={handleclose} className='closeBtn'><Cross /></Button>
        </Modelheader>
        <div className="container" style={{ maxWidth: "calc(100% - 10px)" }}>
          <Row>
            <Col xs={12} md={6}>
              <ImageStyled src={config.strapiUrl + imageUrl} />
            </Col>

            {/* Dropdown menu Start */}

            <Col xs={12} md={6}>
              <ProductInfo>
                <h5>Specify option</h5>
                <Form.Group>
                  <label>Fabric Suggestion</label>
                  <Select 
                    name="country"
                    title="Country"
                    placeholder="Select Option"
                    value={furnitureFabric && furnitureFabric.find(obj => selectedFurnitureFabric.some((elem) => elem.value == obj.value))}
                    options={furnitureFabric}
                    onChange={onFurnitureFabric}
                  />
                </Form.Group>
                <Form.Group>
                  <label>Frame finish</label>
                  <Select
                    placeholder="Select Option"
                    value={frameFinish && frameFinish.find(obj => selectedFinishValue.some((elem) => elem.value == obj.value))}
                    options={frameFinish}
                    onChange={onFrameFinish}
                  />
                </Form.Group>
                <Form.Group>
                  <label>Base/Frame type</label>
                  <Select
                    placeholder="Select Option"
                    value={baseFrametype && baseFrametype.find(obj => selectedFrametype.some((elem) => elem.value == obj.value))}
                    options={baseFrametype}
                    onChange={onFrameType}
                  />
                </Form.Group>
                <Form.Group>
                  <label>Size</label>
                  <Select 
                    placeholder="Select Option"
                    value={furnitureSize && furnitureSize.find(obj => selectedFrameSize.some((elem) => elem.value == obj.value))}
                    options={furnitureSize}
                    onChange={onFrameSize}
                  />
                </Form.Group>
                {
                  isEdit ?
                    <Button onClick={()=>handleSubmitupdate([])}>Edit option</Button>
                    :
                    <Button onClick={handleSubmit}>Confirm option</Button>
                }

              </ProductInfo>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* Model Open End */}

      <Modal show={modelOpen} className="smallModel" style={{ padding: "0" }}>
        <ModelCenterContent>
          <Button onClick={handleModelClose} className="closeBtn"><Cross /></Button>
          <h6>Room board updated successfully</h6>
          <Button className="pinkBtn" onClick={handleRoomBoard}>View room board <span className="roundIcon"><RightArrow /></span></Button>
        </ModelCenterContent>
      </Modal>
    </Container >
  );
};

export default FurnitureTab;

const ProductImageBox = styled("div")({
  position: "relative",
  ".checkSelection": {
    height: "30px",
    width: "30px",
    position: "absolute",
    right: "8px",
    top: "8px",
    padding: "0px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "svg": {
      height: "16px",
      width: "16px",
      fill: "#5514b4",
    },
    "&.active": {
      background: "#5514b4",
      "svg": {
        fill: "#fff",
      },
    }
  },

});

const ModelCenterContent = styled("div")({
  textAlign: "center",
  background: "#5514b4",
  border: "1px solid #fff",
  borderRadius: "10px",
  padding: "12px",
  ".closeBtn": {
    margin: 0,
    background: "#fff",
    opacity: "100%",
    height: "25px",
    width: "25px",
    padding: 0,   
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #fff",
    color: "#5514b4",
    marginRight: 0,
    marginLeft: "auto",
    "svg": {
      fill: "#5514b4",
      height: "16px",
      width: "16px",
    },
    "&:hover": {
      border: "1px solid #fff",
      background: "#5514b4",
      color: "#fff",
      "svg": {
        fill: "#fff",
      }
    },
  },
  "h6": {
    color: "#fff",
    fontSize: "20px",
    fontWeight: 600,
    margin: "30px 0",
  },
  ".pinkBtn": {
    padding: "8px 15px",
    border: "1px solid #fff",
    borderRadius: "5px",
    backgroundColor: "#e60050",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "14px",
    position: "relative",
    maxWidth: "350px",
    textAlign: "center",
    margin: "15px auto",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "&:hover": {
      border: "1px solid #fff",
      backgroundColor: "#e60050",
    },
    ".roundIcon": {
      border: "2px solid #fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "18px",
      width: "18px",
      borderRadius: "50%",
      "svg": {
        fill: "#fff",
        height: "12px",
        width: "12px",
      }
    }
  }
});

const ImageStyled = styled("img")({
  width: "100%",
  height: "auto",
  marginBottom: "10px",
});

const ButtonGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#eee",
  padding: "15px",
  borderRadius: "5px",
  border: "1px solid #e7e7e7",
  gap: "15px",
  marginTop: "30px",
  "button": {
    padding: "8px 15px",
    border: "1px solid #000",
    borderRadius: "5px",
    backgroundColor: "#e60050",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "14px",
    position: "relative",
    maxWidth: "350px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "&:hover": {
      border: "1px solid #000",
      backgroundColor: "#e60050",
    },
    "&:first-child": {
      backgroundColor: "#32008a",
      "&:hover": {
        border: "1px solid #000",
        backgroundColor: "#32008a",
      },
    },
    ".roundIcon": {
      border: "2px solid #fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "18px",
      width: "18px",
      borderRadius: "50%",
      "svg": {
        fill: "#fff",
        height: "12px",
        width: "12px",
      }
    }
  }
});

const ProductInfo = styled("div")({
  "h5": {
    fontSize: "22px",
    lineHeight: "30px",
    margin: "0 0 15px",
    color: "#e60050",
    fontWeight: 700
  },
  "& > div": {
    marginBottom: "18px",
    "label": {
      fontSize: "16px",
      lineHeight: "26px",
      margin: "0 0 2px",
      color: "#5514b4",
      fontWeight: 600
    }
  },
  "button": {
    background: "#5514b4",
    border: "2px solid #5514b4",
    padding: "6px 25px",
    margin: "10px 0 40px",
    "&:hover": {
      border: "2px solid #5514b4",
      background: "#fff",
      color: "#5514b4",
    }
  }
});

const Modelheader = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: "20px",
  padding: "20px",
  ".closeBtn": {
    margin: 0,
    background: "#5514b4",
    opacity: "100%",
    height: "30px",
    width: "30px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #5514b4",
    "&:hover": {
      border: "1px solid #5514b4",
      background: "#5514b4",
      color: "#fff",
    },
    "svg": {
      fill: "#fff",
      height: "18px",
      width: "18px",
    }
  },
  "h6": {
    fontSize: "22px",
    lineHeight: "30px",
    margin: "0 0 5px",
    color: "#e60050",
    fontWeight: 700
  },
  "p": {
    fontSize: "16px",
    lineHeight: "26px",
    margin: "0",
    color: "#5514b4",
    fontWeight: 700
  }
});

const ProductModule = styled("div")({
  width: "100%",
  height: "auto",
  marginBottom: "10px",
  "button": {
    padding: "8px 15px",
    border: "1px solid #000",
    borderRadius: "5px",
    backgroundColor: "#5514b4",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "14px",
    position: "relative",
    maxWidth: "350px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "&:hover": {
      border: "1px solid #000",
      backgroundColor: "#5514b4",
    },
    ".roundIcon": {
      border: "2px solid #fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "18px",
      width: "18px",
      borderRadius: "50%",
      "svg": {
        fill: "#fff",
        height: "12px",
        width: "12px",
      }
    }
  },
  ".productDetailslist": {
    textAlign: "left",
    color: "#aaa",
    fontWeight: 600,
    fontSize: "16px",
    marginBottom: "12px",
    "span": {
      color: "rgb(85, 20, 180)",
    }
  }
});


