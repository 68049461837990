import React from "react";
import styled from "styled-components";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";

// import utils
import { colors } from "../../utils/colors";

const GuidesTab = ({ data }) => {
  return (
    <Row>
      <div>
        <Container>
          <SectionBar>
            {data.sections.map((section, index) => (
              <p key={`p${index}`}>
                <SectionLink href={`#section${section.id}`}>
                  {section.section_title}
                  {index + 1 !== data.sections.length ? <span>|</span> : null}
                </SectionLink>
              </p>
            ))}
          </SectionBar>
        </Container>
      </div>

      {data.sections.map((section) => (
        <SectionBody id={`section${section.id}`} key={`section${section.id}`}>
          <Container>
            <SectionTitle>{section.section_title}</SectionTitle>
            <SectionText
              dangerouslySetInnerHTML={{
                __html: section?.section_content,
              }}
            />
          </Container>
        </SectionBody>
      ))}
    </Row>
  );
};

export default GuidesTab;

const SectionBar = styled("div")({
  display: "flex",
  marginTop: "30px",
});

const SectionBody = styled("div")({
  "&:nth-child(odd)": {
    backgroundColor: "#eee",
  },
  padding: "30px 0",
  marginBottom: "15px",
});

const SectionTitle = styled("p")({
  color: colors.primary,
  fontSize: "32px",
  fontWeight: 500,
});

const SectionText = styled("div")({
  columnCount: 3,
  color: colors.textColor,
  fontSize: "16px",

  "@media screen  and (max-width: 992px)": {
    columnCount: 1,
  },
});

const SectionLink = styled("a")({
  span: {
    margin: "0 5px",
    color: "#000",
    fontWeight: 400,
  },
  "&:hover": {
    color: colors.secondary,
  },
  color: colors.primary,
  fontSize: 18,
  fontWeight: 600,
  textDecoration: "none",
});
