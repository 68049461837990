import React from "react";

const Revit = () => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 32 40"
      style={{
        enableBackground: "new 0 0 32 40",
        width: "20px",
        marginRight: "10px",
        height: "20px",
      }}
    >
      <path
        className="st0"
        fill="#1976D2"
        d="M22,22.6c0-3.1,4-3.8,4-10.6c0-5.7-3-9.2-8.4-10.9C14.5,0.2,10.4,0,6,0C4,0,0,0,0,0v18.9L5,24h8l10,11l9,5
	C32,40,22,25.6,22,22.6z M16,12c0,2.2-1.8,4-4,4h-1c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h2c1.7,0,3,1.3,3,3V12z"
      />
      <path
        className="st1"
        fill="#1565C0"
        d="M16,12.1c0,2.2-1.8,3.9-4,3.9c0,0-0.2,1-2.1,2.8C8.8,19.7,6,21,6,21s11.1,0.7,14.2-5.4
	C19.7,13.8,18.2,12.4,16,12.1z"
      />
      <path
        className="st2"
        fill="#1950A6"
        d="M14,5h-4C8.3,5,7,6.3,7,8v3c0,2.5,1.7,4.6,3.5,4.9C9.6,15.7,9,14.9,9,14v-4c0-1.1,0.9-2,2-2h2c1.7,0,3,1.3,3,3
	v1c0,0,0,0,0,0.1c2.2,0.3,3.7,1.7,4.2,3.5c0.5-1,0.8-2.2,0.8-3.6C21,8.1,17.9,5,14,5z"
      />
      <path
        className="st3"
        fill="#0D47A1"
        d="M10,5C8.3,5,7,6.3,7,8v3c0,2.5,1.7,4.6,3.5,4.9C9.6,15.7,9,14.9,9,14v-4c0-1.1,0.9-2,2-2h2c1.7,0,3,1.3,3,3v1
	c0,2.2-1.8,4-4,4c0,0,6,0,6-6V9c0-2.1-1.7-3.9-3.8-4c-0.1,0-0.1,0-0.2,0H10z M0,0v38l4,2l2-19C6,21,0,18,0,0z"
      />
      <path
        className="st4"
        fill="#3AA2DB"
        d="M4,40l2-19c0,0,6,0,9,2c1.3,0.9,4.9,5.1,8.2,8.9C27.9,37.1,32,40,32,40s-6.9-0.5-9.6-0.9
	c-0.9-0.1-1.7-0.5-2.2-1.2c-1.5-2.1-4.2-7.4-8.2-11.4c-0.2-0.2-0.5-0.5-1-0.5c-0.5,0-1,0.4-1,1v10.3c0,0.4-0.3,0.8-0.7,0.9L4,40z"
      />
    </svg>
  );
};

export default Revit;
