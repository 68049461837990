import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Import Utilsimport { colors } from "../utils/colors";
import { routes } from "../utils/routes";
import { heading } from "../utils/typography";
import config from "../utils/config";

// Import Components
import LoginContainer from "./components/LoginContainer";
import AuthLayout from "./components/AuthLayout";

import Input from "../components/forms/Input";
import Button from "../components/forms/Button";

import User from "../components/icons/User";
import Password from "../components/icons/Password";
import moment from "moment";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorStatus,setErrorStatus]=useState()

  const authUser = async () => {
    setLoading(true);
    setError('');

    const resStrapi = fetch(config.strapiAuthUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    }).then((response) => {
      setErrorStatus(response.status)
      if (response.ok) {
        return response.json();
      }

      setError('We can\'t find an account with those details, please try again.');
      setLoading(false);
    }).then((responseJson) => {
      
    
      const current = new Date();
      const updatedDate = moment(responseJson.user.updated_at).utc().format('YYYY-MM-DD');
      const todayDate = moment(current).utc().format('YYYY-MM-DD');
      const diffInMs = new Date(todayDate) - new Date(updatedDate)
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays > 170) {
        setLoading(false); 
        setError("Sorry, your password has expired. To regain access you'll need permission.");
        
      }
      else
      {
        window.sessionStorage.setItem("jwt", responseJson.jwt);
        window.sessionStorage.setItem("uid", responseJson.user.id);
        window.location.reload();
      }

    }).catch((error) => {
      setLoading(false);
      setError('We can\'t find an account with those details, please try again.');
    });
  };

  const Submit = (e) => {
    e.preventDefault();
    authUser();
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    axios
      .post(`${config.laravelUrl}/api/novapass-request`, 
      {email:email}, 
      {headers: { "Content-Type": "application/json" }})
      .then((res) => {
        setError(res.data.message);

      })
      .catch((err) => {console.error(err)});
  }

  return (
    <AuthLayout>
      <form onSubmit={Submit} method="POST">
        <LoginContainer>
          <h1 style={heading}>Log in</h1>
          <Input
            icon={User}
            type="email"
            name="email"
            placeholder="Email address"
            onChange={setEmail}
          />
          <Input
            icon={Password}
            type="password"
            name="password"
            placeholder="Password"
            onChange={setPassword}
          />
          <Button>{loading ? "Loading..." : "Log in"}</Button>
          <span>{error}</span>{error && errorStatus != 400 ? <ButtonStyled onClick={handleSubmit}>Request approval</ButtonStyled>
          :
           <LinkStyled to={routes.forgottenPasswordPage}>
            Forgot / Reset password?
          </LinkStyled>}
             
        </LoginContainer>
      </form>

      <RegisterContainer>
        <Paragraph>Don't have an account?</Paragraph>
        <Link to={routes.requestAccess}>
          <Button padding="10px 80px" whiteBorder>
            Request access
          </Button>
        </Link>
      </RegisterContainer>
    </AuthLayout>
  );
};

export default Login;

const ButtonStyled = styled.span({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#32008a'
})
const LinkStyled = styled(Link)({
  // "&:visited": {
  //   color: colors.buttonColor,
  // },
  textAlign: "center",
  // color: colors.button,
  marginTop: 15,
});

const RegisterContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 50,
});

const Paragraph = styled.p({
  color: "#ffffff",
  fontSize: 22,
});
