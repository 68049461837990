import React, { useEffect } from "react";
import styled from "styled-components";

// Bootstrap component
import Container from "react-bootstrap/Container";

// Import Components
import Header from "./Header";
import Footer from "./Footer";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { colors } from "../utils/colors";
import config from "../utils/config";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";

const Layout = ({ children, topMenu, textPageTabs, spacePage }) => {
  const TextPageTabs = textPageTabs;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error,setError]=useState({
    name:"",
    email:"",
    message:""
  })
  const [success,setSuccess]=useState(false)
  const location = useLocation()

   useEffect(()=>{
    if(success){
     setTimeout(()=>(
      setSuccess(false)       
   ),3000)}
   },[success])
  const Submit = (e) => {
    e.preventDefault();
    if(name !=="" && email !=="" && message !==""){
      const resStrapi = fetch(`${config.strapiUrl}/feedback-forms`, {
        method: "POST",
        headers: {"Content-Type": "application/json",},
        body: JSON.stringify({name,email,message}),
      }).then((response) => {

        const feedbackApi = fetch(`${config.laravelUrl}/api/feedback-form`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({name,email,message}),
        })

        setName("")
        setEmail("")
        setMessage("")
        setSuccess(true)
        setError({
          name:"",
          email:"",
          message:"",
        })
      })
    }else{
      setSuccess(false)
      setError({
        name:"Please enter name",
        email:"Please enter email",
        message:"Please enter message",
      })
    }
  };
  return (
    <Wrapper>
      <div>
        <Header topMenu={topMenu} />
        {textPageTabs && 
        <>
        <TextPageTabs />
        {location.pathname =="/technical-support" &&
        <Container style={{ padding: "30px 0" }}>
        <form onSubmit={Submit} method="POST">
        <div>
        <SectionTitle>Change request form</SectionTitle>
          <div>
              <InputStyled 
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e)=>setName(e.target.value)}
                />
                <Validation>{error.name && error.name}</Validation>
          </div>
          <div>
              <InputStyled
                type="email"
                name="email"
                placeholder="Email address"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                />
                 <Validation>{error.email && error.email}</Validation>
            </div>
          <TextAreaStyled 
          id="story" 
          name="story"
          rows="5" 
          cols="36" 
          placeholder="Enter your request here..." 
          value={message}
          onChange={(e)=>setMessage(e.target.value)}
          >
          </TextAreaStyled>
          <Validation>{error.message && error.message}</Validation>
        </div>
          <Button type='submit'>Submit change request</Button>
      </form>
      <br/>
     {success &&   
      <Alert variant='success' >
      Message sent successfully
      </Alert>
      }
      </Container>}
      </>
        }
        {spacePage === true ? (
          <Container fluid style={{ position: "relative" }}>
            {children}
          </Container>
        ) : (
          <Container style={{ margin: "30px auto", position: "relative" }}>
            {children}
          </Container>
        )}
      </div>

      <Footer />
    </Wrapper>
  );
};

export default Layout;

const Validation = styled.span({
  color:'red'
})
const TextAreaStyled = styled.textarea({
  "::placeholder": {
    color: colors.bgColor,
  },
  "&:focus": {
    boxShadow: `0 0 2px ${colors.primary}`,
    outline: `none`,
    borderRadius: "5px",
  },
  padding: (props) => (props.withIcon ? "8px 10px 8px 40px" : "8px 10px"),
  marginBottom: "15px",
  border: (props) =>
    props.error ? `1px solid ${colors.secondary}` : "1px solid #000000",
  borderRadius: "5px",
  fontSize: "18px",
  width: "280px",
  maxWidth: "100%",
})

const InputStyled = styled.input({
  "::placeholder": {
    color: colors.bgColor,
  },
  "&:focus": {
    boxShadow: `0 0 2px ${colors.primary}`,
    outline: `none`,
    borderRadius: "5px",
  },
  padding: (props) => (props.withIcon ? "8px 10px 8px 40px" : "8px 10px"),
  marginBottom: "15px",
  border: (props) =>
    props.error ? `1px solid ${colors.secondary}` : "1px solid #000000",
  borderRadius: "5px",
  fontSize: "18px",
  width: "280px",
  maxWidth: "100%",

});
const SectionTitle = styled("p")({
  color: colors.primary,
  fontSize: "32px",
  fontWeight: 500,
});
const Wrapper = styled.div({
  minHeight: "101vh",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});
