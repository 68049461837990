import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import {
  spaceGeneralArrangementTitle,
  spaceRatioText,
  heading3,
} from "../../utils/typography";
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";
import { SRLWrapper } from "simple-react-lightbox";

const lightboxOptions = {
  buttons: {
    showDownloadButton: false
  }
};

const DetailsTab = ({ data }) => {
  const [sticky, setSticky] = useState(false);
  const colRef = useRef();

  const handleScroll = () => {
    if (colRef.current) {
      setSticky(colRef.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} md={6} ref={colRef}>
          <TextBox
            width={colRef?.current?.clientWidth}
            className={sticky ? "px-3 stickyBox" : null}
          >
            <h2 style={spaceGeneralArrangementTitle} className="mb-5">
              Details
            </h2>
            <p
              style={spaceRatioText}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </TextBox>
        </Col>
        <Col xs={12} md={6}>
          <SRLWrapper options={lightboxOptions}>
          {data?.images.map((image) => (
            <div key={`image${image.id}`}>
              <ImageStyled
                src={`${config.strapiUrl}/${image.url}`}
                alt={image.alternativeText}
              />
              <h3 style={heading3} className="mb-5">
                {image.caption}
              </h3>
            </div>
          ))}
          </SRLWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default DetailsTab;

const ImageStyled = styled("img")({
  width: "100%",
  height: "auto",
  marginBottom: "15px",
  cursor: "pointer",
});

const TextBox = styled("div")({
  paddingTop: "15px",
  "&.stickyBox": {
    position: "fixed",
    top: 0,
    backgroundColor: "#fff",
    width: (props) => props.width + "px",
    marginLeft: -15,
  },
});
