import LocalStorageUtil from "./localStorage";

const localStorageRecentlyViewedSpaces = new LocalStorageUtil('recentlyViewedSpaces');

export const recentlyViewed = (pathname = null, data, uid, type = 'spaces') => {
    const currentRecentlyViewedSpaces = localStorageRecentlyViewedSpaces.getFromLocalStorage() ? localStorageRecentlyViewedSpaces.getFromLocalStorage().recentlyViewedSpaces : [];

    if(type === 'spaces' && pathname) {
        data.mainRoute = pathname.split('/').filter(item => item)[0];
    }else {
        data.mainRoute = null;
    }

    if(localStorageRecentlyViewedSpaces.getFromLocalStorage() && localStorageRecentlyViewedSpaces.getFromLocalStorage().uid === uid) {
        if(!currentRecentlyViewedSpaces.find((item) => item.slug === data.slug)) {
            const recentlyViewedSpaces = {
                uid: localStorageRecentlyViewedSpaces.getFromLocalStorage().uid,
                recentlyViewedSpaces: [data, ...currentRecentlyViewedSpaces],
            }
            if(recentlyViewedSpaces.recentlyViewedSpaces.length > 4) {
                recentlyViewedSpaces.recentlyViewedSpaces.pop();
            }

            localStorageRecentlyViewedSpaces.setToLocalStorage(recentlyViewedSpaces);
        }
    }else {
        const recentlyViewedSpaces = {
            uid,
            recentlyViewedSpaces: [data],
        }
        localStorageRecentlyViewedSpaces.setToLocalStorage(recentlyViewedSpaces);
    }
}