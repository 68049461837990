import React from "react";
import { Link, useLocation } from "react-router-dom";

// import utils
import config from "../utils/config";
import { useFetch } from "../utils/hooks";
import { heading2, heading3 } from "../utils/typography";

// components import
import Layout from "../components/Layout";
import Preloader from "../components/Preloader";
import SearchBar from "../components/SearchBar";

// Bootstrap import
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

const SearchPage = ({ topMenu }) => {
  const { search } = useLocation();

  const word = search.split("?s=")[1];


  const results = [];
  const { loading, data } = useFetch(`${config.strapiUrl}/text-pages/search/${word.replace(' ', '+')}`);

  data?.map((page) => {
    results.push(page); 
  });


  return (
    <Layout topMenu={topMenu}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <h2
                className="text-center text-md-left"
                style={{ ...heading2, textAlign: "left" }}
              >
                Search results for <strong>'{word.replace('+', ' ')}'</strong>
              </h2>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center justify-content-md-end"
            >
              <SearchBar />
            </Col>
          </Row>
          <Row className="mt-5">
            {results.map((item) => (
              <Col
                xs={12}
                md={6}
                className="mb-3"
                key={item.url}
                style={{ height: "200px" }}
              >
                <Link to={item.url} style={{ textDecoration: "none" }}>
                  <Row style={{ height: "100%" }}>
                    <Col xs={5}>
                      {item.pic.url && (
                        <img
                          alt={item.pic.alt}
                          src={`${config.strapiUrl}${item.pic.url}`}
                          className="img-fluid search-img"
                        />
                      )}
                    </Col>
                    <Col xs={7} className="d-flex align-items-center">
                      <h3 style={heading3}>{item.name}</h3>
                    </Col>
                  </Row>
                </Link>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Layout>
  );
};

export default SearchPage;
