import React from "react";
import styled from "styled-components";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import utils
import {
  spaceGeneralArrangementTitle,
  spaceRatioTitle,
  spaceRatioText,
} from "../../utils/typography";
import config from "../../utils/config";

// import components
import { imageCaption } from "../../utils/typography";
import Container from "react-bootstrap/esm/Container";
import { SRLWrapper } from "simple-react-lightbox";

const lightboxOptions = {
  buttons: {
    showDownloadButton: false
  }
};

const GeneralArrangementTab = ({
  subtabData,
  mainPic,
  currentType,
  currentSize,
  slug,
}) => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={5}>
          <h2 style={spaceGeneralArrangementTitle}>GA Plan</h2>
          <p
            style={spaceRatioText}
            dangerouslySetInnerHTML={{
              __html: subtabData?.Arrangement_Description,
            }}
          />

          <h3 style={spaceRatioTitle}>Ratio</h3>
          <p
            style={spaceRatioText}
            dangerouslySetInnerHTML={{ __html: subtabData?.Ratio }}
          />

          {mainPic && (
            <ImageStyled
              src={config.strapiUrl + mainPic?.url}
              alt={mainPic?.alternativeText}
            />
          )}
        </Col>
        <Col xs={12} md={7}>
          <SRLWrapper options={lightboxOptions}>
            {subtabData?.Schemas?.map((item) => (
              <div key={item.id}>
                <ImageStyled
                  src={config.strapiUrl + item.url}
                  alt={item.alternativeText}
                />
                <p style={imageCaption}>{item.caption}</p>
              </div>
            ))}
          </SRLWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralArrangementTab;

const ImageStyled = styled("img")({
  width: "100%",
  height: "auto",
  marginBottom: "15px",
});
