import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { routes } from "../utils/routes";
import { colors } from "../utils/colors";

import LoginContainer from "./components/LoginContainer";
import AuthLayout from "./components/AuthLayout";

import Input from "../components/forms/Input";
import Button from "../components/forms/Button";

import { heading } from "../utils/typography";
import config from "../utils/config";

import Spinner from "react-bootstrap/esm/Spinner";
import axios from "axios";

const ForgottenPassowrd = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const[requestMessage,setRequestMessage] = useState(false);
  console.log('requestMessage: ', requestMessage);
  const submit = async () => {
    if (!email || !email.includes("@")) {
      setEmailError(true);
    } else {
      setLoading(true);
      await fetch(`${config.laravelUrl}/api/pass-request`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('data: ', data);
          if (data.message) {
            setRequestMessage(data.status)
            setSubmitted(true);
            setLoading(false);
            setMessage(data.message); 
          }
        });
    }
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    axios
      .post(`${config.laravelUrl}/api/novapass-request`, 
      {email:email}, 
      {headers: { "Content-Type": "application/json" }})
      .then((res) => {
        setRequestMessage(res.data.status)
        setSubmitted(true);
        setLoading(false);
        setMessage(res.data.message); 
      })
      .catch((err) => {console.error(err)});
  }

  return (
    <AuthLayout>
      <LoginContainer>
        <h1 style={heading}>Forgotten password</h1>
        {submitted ? (
            <span className="text-center">{message} 
            {!requestMessage && <ButtonStyled onClick={handleSubmit}>Request Access</ButtonStyled>}</span>
        ) : (
          <div>
            <Input
              error={emailError}
              onChange={(e) => {
                setEmail(e);
                setEmailError(false);
              }}
              type="text"
              placeholder="Email address"
            />
            <Button onClick={submit} width="100%">
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "15px" }}
                />
              )}
              Reset password
            </Button>
          </div>
        )}
        <LinkStyled to={routes.logInPage}>Back to Log in </LinkStyled>
      </LoginContainer>
      <RegisterContainer>
        <Paragraph>Don't have an account?</Paragraph>
        <Link to={routes.requestAccess}>
          <Button padding="10px 80px">Request access</Button>
        </Link>
      </RegisterContainer>
    </AuthLayout>
  );
};

export default ForgottenPassowrd;

const ButtonStyled = styled.span({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#32008a'
})
const LinkStyled = styled(Link)({
  "&:visited": {
    color: colors.buttonColor,
  },
  textAlign: "center",
  color: colors.button,
  marginTop: 15,
});

const RegisterContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 50,
});

const Paragraph = styled.p({
  color: "#ffffff",
  fontSize: 22,
});
