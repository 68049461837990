import { useEffect, useState } from "react";
import config from "../config";

const useFetchTextPage = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const jwt = window.sessionStorage.getItem("jwt") || null;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${config.strapiUrl}/text-pages/${url}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (res.status === 404) {
          const res2 = await fetch(
            `${config.strapiUrl}/text-page-two-columns/${url}`,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          );

          const json = await res2.json();

          setData(json);
          setLoading(false);
        } else {
          const json = await res.json();

          setData(json);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, jwt]);

  return { data, error, loading };
};

export default useFetchTextPage;
