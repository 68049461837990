import React, { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { routes } from "../utils/routes";
import { colors } from "../utils/colors";

import LoginContainer from "./components/LoginContainer";
import AuthLayout from "./components/AuthLayout";

import Input from "../components/forms/Input";
import Button from "../components/forms/Button";

import { heading } from "../utils/typography";
import config from "../utils/config";

import Spinner from "react-bootstrap/esm/Spinner";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [message, setMessage] = useState("");

  const query = new URLSearchParams(useLocation().search);
  const email = query.get("email");
  const token = query.get("token");

  const submit = async () => {
    if (!password || !confirmPassword || password !== confirmPassword) {
      if (!password) setPasswordError(true);
      if (!confirmPassword) setConfirmPasswordError(true);
      if (password !== confirmPassword) {
        setConfirmPasswordError(true);
        setPasswordError(true);
      }
    } else {
      setLoading(true);
      await fetch(`${config.laravelUrl}/api/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          password,
          password_confirmation: confirmPassword,
          token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setSubmitted(true);
            setLoading(false);
            setMessage(data.message);
          }
        });
    }
  };
  return (
    <AuthLayout>
      <LoginContainer>
        <h1 style={heading}>Reset password</h1>
        {submitted ? (
          <>
            <EmailStyled className="text-center">{message}</EmailStyled>
          </>
        ) : (
          <div>
            <div>
              <EmailStyled>{email}</EmailStyled>
            </div>
            <Input
              error={passwordError}
              onChange={(e) => {
                setPassword(e);
                setPasswordError(false);
              }}
              type="password"
              placeholder="New password"
            />
            <Input
              error={confirmPasswordError}
              onChange={(e) => {
                setConfirmPassword(e);
                setConfirmPasswordError(false);
              }}
              type="password"
              placeholder="Confirm password"
            />
            <Button onClick={submit}>
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "15px" }}
                />
              )}
              Update password
            </Button>
          </div>
        )}

        <LinkStyled to={routes.logInPage}>Back to Log in </LinkStyled>
      </LoginContainer>
    </AuthLayout>
  );
};

export default ResetPassword;

const LinkStyled = styled(Link)({
  "&:visited": {
    color: colors.buttonColor,
  },
  textAlign: "center",
  color: colors.button,
  marginTop: 15,
});

const EmailStyled = styled("p")({
  color: colors.primary,
  textAlign: "center",
  fontSize: 18,
  fontWeight: 500,
});
