import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";

const Input = ({ type, placeholder, name, onChange, icon, error, value }) => {
  const Icon = icon;
  return (
    <Wrapper>
      {Icon && (
        <Icon
          style={{
            width: "20px",
            position: "absolute",
            top: "12px",
            left: "15px",
          }}
        />
      )}
      <InputStyled
        withIcon={icon}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        error={error}
        value={value}
      />
    </Wrapper>
  );
};

export default Input;

const InputStyled = styled.input({
  "::placeholder": {
    color: colors.bgColor,
  },
  "&:focus": {
    boxShadow: `0 0 2px ${colors.primary}`,
    outline: `none`,
    borderRadius: "5px",
  },
  padding: (props) => (props.withIcon ? "8px 10px 8px 40px" : "8px 10px"),
  marginBottom: "15px",
  border: (props) =>
    props.error ? `1px solid ${colors.secondary}` : "1px solid #000000",
  borderRadius: "5px",
  fontSize: "18px",
  width: "280px",
  maxWidth: "100%",
});

const Wrapper = styled.div({
  position: "relative",
  zIndex: 2,
  width: "fit-content",
});
