import React, { useState } from "react";
import styled from "styled-components";
import config from "../utils/config";
import { useFetch } from "../utils/hooks";
import Select from 'react-select';

const LocationSelect = ({ center, whiteBorder, passBack }) => {

  const { data } = useFetch(`${config.strapiUrl}/locations`);
  function handleChange(options) {
    passBack(options);
  }
  return (
    <div>
      <label>
        Location
      </label>

      <Select
        isMulti={true}
        onChange={handleChange}
        options={data?.map(location => {
          return {
            value: location.id,
            label: location.Name,
          }
        })} />
    </div>
  );
};

export default LocationSelect;
