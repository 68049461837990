import React from "react";
import styled from "styled-components";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import {
  spaceGeneralArrangementTitle,
  spaceRatioText,
  furnitureTabImageTitle,
} from "../../utils/typography";
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";

const FinishesTab = ({ data }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} style={{ marginBottom: 30 }}>
          <h2 style={spaceGeneralArrangementTitle}>Finishes</h2>
        </Col>
        <Col xs={12} md={5}>
          {data?.image && (
            <ImageStyled src={config.strapiUrl + data?.image?.url} />
          )}
          <p
            style={spaceRatioText}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        </Col>
        <Col xs={12} md={7}>
          <Row>
            {data?.finishes?.map((item) => (
              <Col key={item.id} xs={12} md={6}>
                {item?.image && (
                  <ImageStyled src={config.strapiUrl + item?.image?.url} />
                )}
                <p style={furnitureTabImageTitle}>{item.name}</p>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FinishesTab;

const ImageStyled = styled("img")({
  width: "100%",
  height: "auto",
  marginBottom: "15px",
  cursor: "pointer",
});
