import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Import Utils
import { heading, heading2, heading3, paragraph } from "../utils/typography";
import { useFetch, useRecentlySpaces } from "../utils/hooks";

import config from "../utils/config";

// Import Components
import Layout from "../components/Layout";
import SearchBar from "../components/SearchBar";
import RecentlyViewedSpaces from "./components/RecentlyViewedSpaces";
import Preloader from "../components/Preloader";

const Home = ({ topMenu }) => {
  const { loading, data } = useFetch(`${config.strapiUrl}/home`);
  const { userLoading, userData } = useRecentlySpaces();

  return (
    <Layout topMenu={topMenu}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <h1 style={heading}>{data.title}</h1>
          <SearchBar center />
          <p
            style={paragraph}
            dangerouslySetInnerHTML={{ __html: data.paragraph1 }}
          />
          <p
            style={paragraph}
            dangerouslySetInnerHTML={{ __html: data.paragraph2 }}
          />
          <h2 style={heading2}>{data.subtitle1}</h2>
          <QuickLinksWrapper className="d-block d-md-flex flex-wrap">
            {data.quick_links &&
              data?.quick_links.map((item) => (
                <LinkStyled key={item.id} to={item.URL}>
                  <QuickLinksContainer>
                    <img
                      src={`${config.strapiUrl}${item.Img.url}`}
                      alt={item.Img.alternativeText}
                      className="img-fluid"
                    />
                    <h3 style={heading3}>{item.Title}</h3>
                  </QuickLinksContainer>
                </LinkStyled>
              ))}
          </QuickLinksWrapper>
          <h2 style={heading2}>{data.subtitle2}</h2>
          {userLoading ? (
            "Loading...."
          ) : (
            <RecentlyViewedSpaces data={userData} />
          )}
        </>
      )}
    </Layout>
  );
};

export default Home;

export const QuickLinksWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
});

export const QuickLinksContainer = styled.div({
  textAlign: "center",
  maxWidth: "350px",
  width: "100%",
  margin: "0 10px",
});

export const LinkStyled = styled(Link)({
  textDecoration: "none",
});
