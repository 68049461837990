import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// import utils
import config from "../utils/config";
import { useFetch } from "../utils/hooks";
import { heading2, heading3 } from "../utils/typography";

// components import
import Layout from "../components/Layout";
import Preloader from "../components/Preloader";

// Bootstrap import
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Input from "../components/forms/Input";
import Search from "../components/icons/Search";
import LocationSelect from "../components/LocationSelect";
import SpaceSelect from "../components/SpaceSelect";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { SRLWrapper } from "simple-react-lightbox";
const qs = require('qs');
const lightboxOptions = {
    buttons: {
        showDownloadButton: false
    }
};
const PhotoGallery = ({ topMenu }) => {

    const [photos, setPhotos] = useState([]);

    const handleClick = () => {
        let query = {
            _where: {},
            _sort: "published_at:DESC",
        };

        if (state.locations.length === 0 && state.spaces.length === 0 && (state.keyword === null || state.keyword === '')) {
            query._limit = 20;
        } else {
            if (state.keyword !== null && state.keyword !== '') {
                query._where.Caption_contains = state.keyword
            }

            if (state.locations.length > 0) {
                let locations = state.locations.map(location => location.value);
                query._where.location = locations;
            }

            if (state.spaces.length > 0) {
                let spaces = state.spaces.map(space => space.value);
                query._where.space = spaces;
            }
        }

        const jwt = window.sessionStorage.getItem("jwt") || null;

        const response = fetch(`${config.strapiUrl}/photos?${qs.stringify(query)}`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        }).then(resp => resp.json())
            .then(data => {
                setPhotos(data);
            });
    }

    useEffect(() => {
        handleClick()
    }, []);
    let state = {
        keyword: null,
        locations: [],
        spaces: []
    };

    function updateLocations(val) {
        state.locations = val;
    }

    function updateSpaces(val) {
        state.spaces = val;
    }

    return (
        <Layout topMenu={topMenu}>

            <>
                <Row className="mt-3">
                    <Col xs={12}>
                        <h2
                            style={{ ...heading2, textAlign: "left" }}
                        >
                            Photo Gallery
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <label>
                            Keyword
                        </label>
                        <Input
                            name="keyword"
                            icon={Search}
                            placeholder="Keywords"
                            onChange={(e) => state.keyword = e.replace(' ', '+')}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <LocationSelect passBack={updateLocations} />
                    </Col>
                    <Col xs={12} md={3}>
                        <SpaceSelect passBack={updateSpaces} />
                    </Col>
                    <Col xs={12} md={3}>
                        <br />
                        <Button onClick={handleClick}>Search</Button>
                    </Col>
                </Row>
                <SRLWrapper options={lightboxOptions}>

                    <Row className="mt-3">
                        {photos?.map(photo => (
                            <Col xs={6} md={3}>
                                <div key={photo.id}>
                                    <ImageStyled
                                        src={config.strapiUrl + photo.Image.url}
                                        alt={photo.Caption}
                                    />
                                    <p>{photo.Caption}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </SRLWrapper>
            </>

        </Layout>
    );
};

export default PhotoGallery;

const ImageStyled = styled("img")({
    cursor: "pointer",
    width: "100%",
    height: "200px",
    objectFit: "cover",
    marginBottom: "5px",
});
