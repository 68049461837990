import React from "react";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import { spaceGeneralArrangementTitle } from "../../utils/typography";
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";
import Button from "../../components/forms/Button";
import { colors } from "../../utils/colors";
import Pdf from "../../components/icons/PDF";
import Revit from "../../components/icons/Revit";
import Word from "../../components/icons/Word";

const DetailsTab = ({ data, rds }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <h2 style={spaceGeneralArrangementTitle} className="mb-5">
            Downloads
          </h2>
          <div>
            {data.technical_drawing && (
              <a href={`${config.strapiUrl}${data.technical_drawing.url}`}>
                <Button
                  marginRight="15px"
                  bgColor={colors.secondary}
                  width="250px"
                  height="44px"
                  downloadBtn={true}
                >
                  <Pdf />
                  Technical drawing
                </Button>
              </a>
            )}
          </div>
          <div>
            {data.revit_model && (
              <a href={`${config.strapiUrl}${data.revit_model.url}`}>
                <Button
                  marginRight="15px"
                  bgColor={colors.secondary}
                  width="250px"
                  height="44px"
                  downloadBtn={true}
                >
                  <Revit />
                  Revit model
                </Button>
              </a>
            )}
          </div>
          <div>
            {data.NBS_specification && (
              <a href={`${config.strapiUrl}${data.NBS_specification.url}`}>
                <Button
                  marginRight="15px"
                  bgColor={colors.secondary}
                  width="250px"
                  height="44px"
                  downloadBtn={true}
                >
                  <Word />
                  NBS Specification
                </Button>
              </a>
            )}
          </div>
          <div>
            {rds && (
              <a href={`${config.strapiUrl}${rds.url}`}>
                <Button
                  marginRight="15px"
                  bgColor={colors.secondary}
                  width="250px"
                  height="44px"
                  downloadBtn={true}
                >
                  <Word />
                  RDS
                </Button>
              </a>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DetailsTab;
