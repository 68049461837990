import React from "react";
import styled from "styled-components";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import { spaceGeneralArrangementTitle } from "../../utils/typography";
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";
import { SRLWrapper } from "simple-react-lightbox";
import { imageCaption } from "../../utils/typography";

const lightboxOptions = {
  buttons: {
    showDownloadButton: false
  }
};

const PhotosTab = ({ data, rds }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <h2 style={spaceGeneralArrangementTitle} className="mb-3">
            Photos
          </h2>
        </Col>
      </Row>
      <SRLWrapper options={lightboxOptions}>
        <Row>
          {data?.Photos?.map((item) => (
            <Col xs={4} md={3} className="pe-0">
              <div key={item.id}>
                <ImageStyled
                  src={config.strapiUrl + item.url}
                  alt={item.alternativeText}
                />
                <p style={imageCaption}>{item.caption}</p>
              </div>
            </Col>
          ))}
        </Row>
      </SRLWrapper>
    </Container>
  );
};

export default PhotosTab;

const ImageStyled = styled("img")({
  cursor: "pointer",
  width: "100%",
  height: "200px",
  objectFit: "cover",
  marginBottom: "5px",
});
