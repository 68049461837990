import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/Nav";

// import utils
import { colors } from "../../utils/colors";
import { spaceTitle } from "../../utils/typography";

// import components
import Button from "../../components/forms/Button";
import {useHistory, useLocation} from "react-router";

const SubtabBar = ({ name,
  spaceType,
  spaceSize,
  typeLinks,
  sizeLinks,
  slug,
  specific,
  spaceTabs,
  sizes,
  types,
}) => {
  const { pathname } = useLocation();
  const mainRoute = pathname.split('/').filter(item => item)[0];

  return (
    <Row>
      <Wrapper>
        <Container>
          <Row
	  		className="gx-md-5"
	  		>
            <Col xs={12} md={4} className="px-md-2">
              <h1 style={spaceTitle}>{name}</h1>
            </Col>
            <Col xs={12} md={8} className="px-md-2">
              <Row>
                <Col
                  xs={12}
                  className="mt-2 d-block d-md-flex justify-content-md-end size-btns"
                >
                  <SpaceTypeText style={{ margin: "7px 15px 0 0" }}>
                    Space type & size:
                  </SpaceTypeText>
                  <div className="d-block d-md-flex">
                    <div>
                      {types?.map((type) => {
                        return typeLinks.find((e) => e === type.id) ? (
                          <Link
                            key={`type${type.id}-${type.slug}`}
                            to={`/${mainRoute}/${slug}/${type.Label}/${spaceSize}/${specific}`}
                          >
                            <Button
                              marginRight="5px"
                              isActive={
                                spaceType === type.Label ? "true" : "false"
                              }
                              isDisabled={
                                !typeLinks.find((e) => e === type.id)
                                  ? "true"
                                  : "false"
                              }
                            >
                              {type.Label}
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            key={`type${type.id}-${type.slug}`}
                            marginRight="5px"
                            isActive={
                              spaceType === type.Label ? "true" : "false"
                            }
                            isDisabled={
                              !typeLinks.find((e) => e === type.id)
                                ? "true"
                                : "false"
                            }
                          >
                            {type.Label}
                          </Button>
                        );
                      })}
                    </div>
                    <div>
                      {sizes?.map((size) => {
                        return sizeLinks.find((e) => e === size.id) ? (
                          <Link
                            key={`size${size.id}-${size.slug}`}
                            to={`/${mainRoute}/${slug}/${spaceType}/${size.Label}/${specific}`}
                          >
                            <Button
                              marginRight="5px"
                              isActive={
                                spaceSize === size.Label ? "true" : "false"
                              }
                              isDisabled={
                                !sizeLinks.find((e) => e === size.id)
                                  ? "true"
                                  : "false"
                              }
                            >
                              {slug === 'showers' || slug === 'toilets' ? (
                                {
                                  'SM': 'V1',
                                  'MD': 'V2',
                                  'LG': 'V3',
                                  'XL': 'V4'
                                }[size.Label]
                              ) : (
                                size.Label
                              )}
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            key={`size${size.id}-${size.slug}`}
                            marginRight="5px"
                            isActive={
                              spaceSize === size.Label ? "true" : "false"
                            }
                            isDisabled={
                              !sizeLinks.find((e) => e === size.id)
                                ? "true"
                                : "false"
                            }
                          >
                            {slug === 'showers' || slug === 'toilets' ? (
                              {
                                'SM': 'V1',
                                'MD': 'V2',
                                'LG': 'V3',
                                'XL': 'V4'
                              }[size.Label]
                            ) : (
                              size.Label
                            )}
                          </Button>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <NavStyled className="d-block d-md-flex">
            {spaceTabs.map((tab) => (
              <div
                style={{ display: "flex", marginTop: "20px" }}
                key={tab.__component}
              >
                <Nav.Item>
                  <LinkStyled
                    className={
                      tab.__component.split(".")[1] === "rds"
                        ? "text-uppercase"
                        : null
                    }
                      // className={
                      //   tab.__component.split(".")[1] === "finishes"
                      //     ? "d-none"
                      //     : null
                      // }
                    to={`/${mainRoute}/${slug}/${spaceType}/${spaceSize}/${
                      tab.__component.split(".")[1]
                    }`}
                    istabactive={
                      specific === tab.__component.split(".")[1]
                        ? "true"
                        : "false"
                    }
                  >
                    {tab.__component.split(".")[1] === "general-arrangement"
                      ? "GA Plan"
                      : tab.__component.split(".")[1].replace("-", " ")}
                  </LinkStyled>
                </Nav.Item>
              </div>
            ))}
          </NavStyled>
        </Container>
      </Wrapper>
    </Row>
  );
};

export default SubtabBar;

const SpaceTypeText = styled("span")({
  margin: "12px 30px 0 0",
  color: colors.primary,
});

const Wrapper = styled("div")({
  backgroundColor: "#ddd",
  paddingTop: "30px",
});

const LinkStyled = styled(Link)({
  "&:hover": {
    color: colors.secondary,
  },
  color: colors.primary,
  backgroundColor: (props) =>
    props.istabactive === "true" ? "#fff" : colors.bgColor,
  textDecoration: "none",
  fontWeight: 600,
  textTransform: "capitalize",
  margin: "0 5px",
  padding: "15px 10px",
  borderRadius: "7px 7px 0 0",

  "&.text-uppercase": {
    textTransform: "uppercase",
  },
});

const NavStyled = styled(Nav)({
  marginBottom: "12px",
  marginLeft: "-20px",
});
