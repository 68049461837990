import React from "react";
import styled from "styled-components";

import Spinner from "react-bootstrap/Spinner";

const Preloader = ({ fullScreen }) => {
  return (
    <Wrapper fullScreen={fullScreen}>
      <Spinner animation="border" />
    </Wrapper>
  );
};
export default Preloader;

const Wrapper = styled("div")({
  height: (props) => (props.fullScreen ? "100vh" : "auto"),
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 100,
});
