import { colors } from "./colors";

export const heading = {
  color: colors.primary,
  fontSize: "30px",
  fontWeight: "400",
  margin: "0",
  marginBottom: "30px",
  textAlign: "center",
};

export const heading2 = {
  color: colors.primary,
  fontSize: "26px",
  fontWeight: "400",
  margin: "0",
  marginBottom: "30px",
  textAlign: "center",
};

export const heading3 = {
  color: colors.primary,
  fontSize: "18px",
  margin: "0",
  marginBottom: "30px",
  textAlign: "center",
  fontWeight: "600",
  marginTop: 10,
};

export const paragraph = {
  color: colors.textColor,
  fontSize: "18px",
  textAlign: "center",
  maxWidth: 1000,
  margin: "30px auto",
};

export const menuSection = {
  fontSize: "18px",
  color: colors.buttonColor,
  textDecoration: "none",
};

export const spaceTitle = {
  fontSize: "32px",
  color: colors.primary,
  marginBottom: "30px",
};

export const spaceGeneralArrangementTitle = {
  color: colors.secondary,
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "15px",
};

export const spaceRatioTitle = {
  color: colors.primary,
  fontSize: "22px",
  fontWeight: 600,
  margin: "30px 0 15px 0",
};

export const spaceRatioText = {
  color: colors.textColor,
};

export const imageCaption = {
  textAlign: "center",
  color: colors.textColor,
  fontWeight: 700,
  marginBottom: 30,
};

export const furnitureTabImageTitle = {
  textAlign: "center",
  color: colors.primary,
  fontWeight: 600,
  fontSize: 18,
};
