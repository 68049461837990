import React from "react";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";

const AuthLayout = ({ children }) => {
  return (
    <Wrapper>
      <TopContainer>
        <Header />
        {children}
      </TopContainer>
      <Footer />
    </Wrapper>
  );
};

export default AuthLayout;

const Wrapper = styled.div({
  minHeight: "100vh",
  background: "url(/images/bg-image.jpg)",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});

const TopContainer = styled.div({
  width: "100%",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});
