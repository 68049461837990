import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Bootstrap component
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import { colors } from "../utils/colors";
import { menuSection } from "../utils/typography";

const TopMenu = ({ activeItem, topMenu }) => {
  const [moved, setMoved] = useState(false);

  if (moved) {
    setTimeout(() => {
      setMoved(false);
    }, 100);
  }

  return (
    <NavStyled>
      {topMenu?.Top_level_items?.map((item) => (
        <div key={item.id}>
          {!moved && item?.sections?.length ? (
            <Dropdown>
              <ToggleStyled activeitem={`${activeItem === item.slug}`}>
                {item.Label}
              </ToggleStyled>
              <MenuStyled>
                <Row>
                  {item.sections.map((section) => (
                    <Col key={section.label}>
                      {(section.text_pages || section.text_page_two_columns) &&
                      section.spaces.length === 0 ? (
                        <p>
                        <Link
                          style={menuSection}
													className='nav-menu'
                          to={`/${
                            section?.text_pages?.slug ||
                            section?.text_page_two_columns?.slug
                          }`}
                          onClick={() => setMoved(true)}
                        >
                          {section.label}
                        </Link></p>
                      ) : (
                        <p style={menuSection}>{section.label}</p>
                      )}
                      {section.spaces &&
                        section.spaces.map((menuItem) => {
                          return (
                            <Item
                              key={menuItem.slug}
                              to={`/${item.slug}/${menuItem.slug}`}
                              onClick={() => setMoved(true)}
                            >
                              {menuItem.top_menu_label}
                            </Item>
                          );
                        })}
                      {section.text_pages &&
                        section.text_pages.map((menuItem) => {
                          return (
                            <Item
                              key={menuItem.slug}
                              to={`/${menuItem.slug}`}
                              onClick={() => setMoved(true)}
                            >
                              {menuItem.Title}
                            </Item>
                          );
                        })}
                      {section.text_page_two_columns &&
                        section.text_page_two_columns.map((menuItem) => {
                          return (
                            <Item
                              key={menuItem.slug}
                              to={`/${menuItem.slug}`}
                              onClick={() => setMoved(true)}
                            >
                              {menuItem.Page_title}
                            </Item>
                          );
                        })}
                    </Col>
                  ))}
                </Row>
              </MenuStyled>
            </Dropdown>
          ) : (
            <TopLevelItem
							className='nav-menu'
              to={`/${item?.text_page === null ? item.slug : item?.text_page?.slug}`}
              activeitem={`${activeItem === item.slug}`}
            >
              {item.Label}
            </TopLevelItem>
          )}
        </div>
      ))}

      <button onClick={()=>{
         window.location.href = '/login'
        sessionStorage.clear()
        localStorage.clear()}}>Logout</button>
    </NavStyled>
  );
};

export default TopMenu;

const TopLevelItem = styled(Link)({
  "&:hover": {
    color: "#000",
    backgroundColor: "#fff",
  },
  backgroundColor: (props) =>
    props.activeitem === "true" ? "#fff!important" : "transparent",
  color: (props) => (props.activeitem === "true" ? "#000!important" : "#fff"),
  height: "62px",
  fontSize: "16px",
  whiteSpace: "break-spaces",
  border: `1px solid ${colors.primary}`,
  textAlign: "center",
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.25em",
  padding: "0 6px",
  transition:
    "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
});

const NavStyled = styled(Nav)({
  alignItems: "center",
  height: "100%",
  justifyContent: "flex-end",
  flexWrap: "nowrap",
});

const Item = styled(Link)({
  fontSize: "14px",
  color: colors.textColor,
  textDecoration: "none",
  display: "block",
});

const ToggleStyled = styled(Dropdown.Toggle)({
  "&::after": {
    display: "none",
    borderColor: "transparent",
  },
  "&:hover, &:focus, &:active": {
    backgroundColor: "#fff!important",
    color: "#000!important",
    boxShadow: "none!important",
    border: `1px solid ${colors.primary}!improtant`,
    outilne: "none!important",
  },
  "&[aria-expanded=true]": {
    boxShadow: "none",
    borderColor: `${colors.primary}!important`,
  },

  backgroundColor: (props) =>
    props.activeitem === "true" ? "#fff!important" : "transparent",
  color: (props) => (props.activeitem === "true" ? "#000!important" : "#fff"),
  fontSize: "16px",
  whiteSpace: "break-spaces",
  border: `1px solid ${colors.primary}`,
});

const MenuStyled = styled(Dropdown.Menu)({
  width: "800px",
  top: "-5px!important",
  borderRadius: "0 0 5px 5px",
  border: `1px solid ${colors.primary}`,
  borderTop: "none",
  padding: "30px",
});
