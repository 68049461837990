import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";

import Download from "../icons/Download";

const Button = ({
  children,
  onClick,
  padding,
  whiteBorder,
  marginRight,
  bgColor,
  isActive,
  width,
  height,
  downloadBtn,
  isDisabled,
}) => {
  const border = whiteBorder ? "#fff" : "#000";
  return (
    <ButtonStyled
      padding={padding}
      marginRight={marginRight}
      onClick={onClick}
      border={border}
      bgColor={bgColor}
      isactive={isActive}
      width={width}
      height={height}
      downloadBtn={downloadBtn}
      className={isDisabled === "true" ? "disabled" : null}
    >
      <div
        className={`d-flex ${
          downloadBtn === true
            ? "justify-content-between"
            : "justify-content-center"
        } `}
      >
        <div>{children}</div>
        {downloadBtn && <Download />}
      </div>
    </ButtonStyled>
  );
};

export default Button;

const ButtonStyled = styled.button({
  padding: (props) => (props.padding ? props.padding : "8px 10px"),
  marginBottom: "15px",
  marginRight: (props) => props.marginRight,
  border: (props) => `1px solid ${props.border}`,
  borderRadius: "5px",
  backgroundColor: (props) =>
    props.bgColor
      ? props.bgColor
      : props.isactive === "true"
      ? colors.secondary
      : colors.buttonColor,
  color: "#ffffff",
  fontWeight: "600",
  fontSize: "14px",
  cursor: "poiner",
  position: "relative",
  maxWidth: "350px",
  width: (props) => props.width,
  height: (props) => props.height,
  textAlign: (props) => (props.downloadBtn === true ? "left" : "center"),

  "&.disabled": {
    cursor: "default",
    filter: "opacity(30%)",
  },
});
