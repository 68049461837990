import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { routes } from "../utils/routes";
import { colors } from "../utils/colors";

import LoginContainer from "./components/LoginContainer";
import AuthLayout from "./components/AuthLayout";

import Input from "../components/forms/Input";
import Button from "../components/forms/Button";

import { heading } from "../utils/typography";
import config from "../utils/config";

import Spinner from "react-bootstrap/esm/Spinner";

const RequestAccess = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [message, setMessage] = useState("");

  const submit = async () => {
    if (!email || !name || !email.includes("@")) {
      if (!email || !email.includes("@")) setEmailError(true);
      if (!name) setNameError(true);
    } else {
      setLoading(true);
      await fetch(`${config.laravelUrl}/api/auth-request`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, name }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setSubmitted(true);
            setLoading(false);
            setMessage(data.message);
          }
        });
    }
  };
  return (
    <AuthLayout>
      <LoginContainer>
        <h1 style={heading}>Request access</h1>
        {submitted ? (
          <>
            <p className="text-center">{message}</p>
          </>
        ) : (
          <div>
            <Input
              error={emailError}
              onChange={(e) => {
                setEmail(e);
                setEmailError(false);
              }}
              type="text"
              placeholder="Email address"
            />
            <Input
              error={nameError}
              onChange={(e) => {
                setName(e);
                setNameError(false);
              }}
              type="text"
              placeholder="Name"
            />
            <Button onClick={submit} width="100%">
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "15px" }}
                />
              )}
              Send request
            </Button>
          </div>
        )}

        <LinkStyled to={routes.logInPage}>Back to Log in </LinkStyled>
      </LoginContainer>
    </AuthLayout>
  );
};

export default RequestAccess;

const LinkStyled = styled(Link)({
  "&:visited": {
    color: colors.buttonColor,
  },
  textAlign: "center",
  color: colors.button,
  marginTop: 15,
});
