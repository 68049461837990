export const routes = {
  homePage: "/",
  logInPage: "/login",
  forgottenPasswordPage: "/forgotten-password",
  requestAccess: "/request-access",
  technicalSupport: "/technical-support",
  searchPage: "/search",

  visionStatement: "/better-workplace-strategy/vision-statement",
  betterWorkplaceStrategySection: "/better-workplace-strategy/:section",
  betterWorkplaceStrategySlug: "/better-workplace-strategy/:section/:slug",

  welcomeExperience: "/welcome-experience",
  workingConnectFloors: "/:category/:slug",
  workingConnectFloorsVariants: "/:category/:slug/:type/:size/:specific",
  workingConnectFloorsVariantsWithDir:
    "/:category/:slug/:type/:size/:specific/:dir",
  workingConnectFloorsSection: "/working-and-connect-floors/:section",

  customersFacilities: "/customer-facilities",

  supportFunctions: "/support-functions",

  generalBuildingAmenities: "/general-building-amenities",

  buildingSelectinGuide: "/building-selection-guide",

  photoGallery: "/photo-gallery",

  textPages: "/:slug",

  createPassword: "/create-password",
  resetPassword: "/reset-password",
};
