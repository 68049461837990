import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import {
  spaceGeneralArrangementTitle,
  spaceRatioText,
  heading3,
} from "../../utils/typography";
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";
import { SRLWrapper } from "simple-react-lightbox";

const lightboxOptions = {
  buttons: {
    showDownloadButton: false
  }
};

// import { useParams } from "react-router";

const ElevationsTab = ({ data }) => {
  // const { dir } = useParams();
  // const [northLoaded, setNorthLoaded] = useState(false);
  // const [eastLoaded, setEastLoaded] = useState(false);
  // const [southLoaded, setSouthLoaded] = useState(false);
  // const [westLoaded, setWestLoaded] = useState(false);
  const [sticky, setSticky] = useState(false);

  const colRef = useRef();

  // const Scroll = () => {
  //   if (typeof dir !== "undefined") {
  //     document.getElementById(dir)?.scrollIntoView();
  //   }
  // };

  // if ((northLoaded, eastLoaded, southLoaded, westLoaded)) {
  //   Scroll();
  // }

  const handleScroll = () => {
    if (colRef.current) {
      setSticky(colRef.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} md={6} ref={colRef}>
          <TextBox
            width={colRef?.current?.clientWidth}
            className={sticky ? "px-3 stickyBox" : null}
          >
            <h2 style={spaceGeneralArrangementTitle} className="mb-5">
              Elevations
            </h2>
            <p
              style={spaceRatioText}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </TextBox>
        </Col>
        {/* <Col xs={12} md={6}>
          {data?.north && (
            <>
              <ImageStyled
                id="north"
                src={`${config.strapiUrl}/${data.north.url}`}
                alt={data.north.alternativeText}
                onLoad={() => setNorthLoaded(true)}
              />
              <h3 style={heading3}>North</h3>
            </>
          )}

          {data?.east && (
            <>
              <ImageStyled
                id="east"
                src={`${config.strapiUrl}/${data.east.url}`}
                alt={data.east.alternativeText}
                onLoad={() => setEastLoaded(true)}
              />
              <h3 style={heading3}>East</h3>
            </>
          )}

          {data?.south && (
            <>
              <ImageStyled
                id="south"
                src={`${config.strapiUrl}/${data.south.url}`}
                alt={data.south.alternativeText}
                onLoad={() => setSouthLoaded(true)}
              />
              <h3 style={heading3}>South</h3>
            </>
          )}

          {data?.west && (
            <>
              <ImageStyled
                id="west"
                src={`${config.strapiUrl}/${data.west.url}`}
                alt={data.west.alternativeText}
                onLoad={() => setWestLoaded(true)}
              />
              <h3 style={heading3}>West</h3>
            </>
          )}
        </Col> */}

        <Col xs={12} md={6}>
          <SRLWrapper options={lightboxOptions}>
            {data?.images?.map((image) => (
              <div key={`image${image.id}`}>
                <ImageStyled
                  src={`${config.strapiUrl}/${image.url}`}
                  alt={image.alternativeText}
                />
                <h3 style={heading3} className="mb-5">
                  {image.caption}
                </h3>
              </div>
            ))}
          </SRLWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default ElevationsTab;

const ImageStyled = styled("img")({
  width: "100%",
  height: "auto",
  marginBottom: "15px",
  cursor: "pointer",
  marginTop: "30px",
});

const TextBox = styled("div")({
  paddingTop: "15px",
  "&.stickyBox": {
    position: "fixed",
    top: 0,
    backgroundColor: "#fff",
    width: (props) => props.width + "px",
    marginLeft: -15,
  },
});
