import React from "react";
import styled from "styled-components";

// Bootstrap components
import Image from "react-bootstrap/Image";

// Import utils
import { colors } from "../../utils/colors";
import { useFetch } from "../../utils/hooks";
import config from "../../utils/config";

const SpaceWrapper = ({ id }) => {
  const { loading, data } = useFetch(`${config.strapiUrl}/spaces/${id}`);
  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <Wrapper>
            <ImageStyled src={config.strapiUrl + data?.Main_pic.formats.thumbnail.url} />
          </Wrapper>
          <SpaceTitle>{data?.Name}</SpaceTitle>
        </>
      )}
    </>
  );
};

export default SpaceWrapper;

const Wrapper = styled("div")({
  border: "1px solid #000",
  textAlign: "center",
  borderRadius: "5px",
  padding: "15px 30px",
});

const SpaceTitle = styled("p")({
  margin: 10,
  textAlign: "center",
  color: colors.primary,
  fontWeight: "600",
  fontSize: 18,
});

const ImageStyled = styled(Image)({
  width: "100%",
  height: "auto",
});
