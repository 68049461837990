import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Bootstrap component
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Import Utils
import { colors } from "../utils/colors";
import { routes } from "../utils/routes";

// Import Components
import Button from "./forms/Button";
import SearchBar from "./SearchBar";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col className="text-center text-md-start">
            <Link to={routes.technicalSupport}>
              <Button padding="8px 30px" whiteBorder>
                Feedback
              </Button>
            </Link>
          </Col>
          <Col className="d-flex justify-content-center justify-content-md-end">
            <SearchBar whiteBorder />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div({
  backgroundColor: colors.primary,
  paddingTop: "30px",
  paddingBottom: "15px",
});
