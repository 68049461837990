import { useEffect, useState } from "react";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const jwt = window.sessionStorage.getItem("jwt") || null;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (jwt) {
        try {
          const res = await fetch(url, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          const json = await res.json();

          setData(json);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      } else {
        try {
          const res = await fetch(url);
          const json = await res.json();

          setData(json);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [url, jwt]);

  return { data, error, loading, setLoading };
};

export default useFetch;
