import { useEffect, useState } from "react";
import config from "../config";

const useRecentlySpaces = () => {
  const [userData, setUserData] = useState(null);
  const [userError, setUserError] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  const jwt = window.sessionStorage.getItem("jwt") || null;

  useEffect(() => {
    const fetchData = async () => {
      setUserLoading(true);

      try {
        const res = await fetch(`${config.strapiUrl}/users/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const json = await res.json();


        setUserData(json.recently_viewed_spaces);
        setUserLoading(false);
      } catch (error) {
        setUserError(error);
        setUserLoading(false);
      }
    };

    fetchData();
  }, [jwt]);

  return { userData, userError, userLoading };
};

export default useRecentlySpaces;
