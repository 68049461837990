import React from "react";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";

// import utils
import config from "../../utils/config";
import Container from "react-bootstrap/esm/Container";
import PDFViewer from "mgr-pdf-viewer-react";

const DetailsTab = ({ data }) => {
  console.log(`${config.strapiUrl}${data.RDS_viewable.url}`);
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <div>
            {data.RDS_viewable && (
              <PDFViewer
                style={{
                  maxWidth: "100%",
                  "& canvas": {
                    maxWidth: "100%",
                    height: "auto",
                  },
                }}
                document={{
                  url: `${config.strapiUrl}${data.RDS_viewable.url}`,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DetailsTab;
