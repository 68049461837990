import React, { useState } from "react";
import styled from "styled-components";
import config from "../utils/config";
import { useFetch } from "../utils/hooks";
import Select from 'react-select';
const qs = require('qs');

const SpaceSelect = ({ center, whiteBorder, passBack }) => {

  let query = {
    _limit: 40,
  };

  const { data } = useFetch(`${config.strapiUrl}/spaces?${qs.stringify(query)}`);
  function handleChange(options) {
    passBack(options);
  }

  return (
    <div>
      <label>
        Spaces
      </label>

      <Select
        isMulti={true}
        onChange={handleChange}
        options={data?.map(location => {
          return {
            value: location.id,
            label: location.Name,
          }
        })} />
    </div>
  );
};

export default SpaceSelect;
