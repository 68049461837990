import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

//Import utils
import { routes } from "./utils/routes";
import { useFetch } from "./utils/hooks";
import config from "./utils/config";

//Import Components
import Login from "./Auth/Login";
import RequestAccess from "./Auth/RequestAccess";
import ForgottenPassowrd from "./Auth/ForgottenPassowrd";
import Home from "./Home/Home";
import BetterWorkplaceSectionPage from "./BetterWorkplace/components/BetterWorkplaceSectionPage";
import SearchPage from "./SearchPage/SearchPage";
import WorkingConnectFloors from "./WorkingConnectFloors/WorkingConnectFloors";
import Preloader from "./components/Preloader";
import TextPage from "./TextPage/TextPage";
import CreatePassword from "./Auth/CreatePassword";
import ResetPassword from "./Auth/ResetPassword";
import PhotoGallery from "./PhotoGallery/PhotoGallery";

const App = () => {
  const isAuthenticated = window.sessionStorage.getItem("jwt") || null;

  const { data, loading } = useFetch(`${config.strapiUrl}/top-menu`);

  return (
    <Router>
      {loading ? (
        <Preloader fullScreen />
      ) : (
        <Switch>
          {/* Unprotected routes */}
          <Route path={routes.logInPage} exact>
            {!isAuthenticated ? <Login /> : <Redirect to={routes.homePage} />}
          </Route>
          <Route path={routes.forgottenPasswordPage} exact>
            {!isAuthenticated ? (
              <ForgottenPassowrd />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route path={routes.requestAccess} exact>
            {!isAuthenticated ? (
              <RequestAccess />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route path={routes.createPassword} exact>
            {!isAuthenticated ? (
              <CreatePassword />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route path={routes.resetPassword} exact>
            {!isAuthenticated ? (
              <ResetPassword />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>

          {/* Protected routes */}
          <Route exact path={routes.homePage}>
            {isAuthenticated ? (
              <Home topMenu={data} />
            ) : (
              <Redirect to={routes.logInPage} />
            )}
          </Route>
          <Route path={routes.photoGallery}>
            {isAuthenticated ? (
              <PhotoGallery topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route path={routes.betterWorkplaceStrategySection}>
            {isAuthenticated ? (
              <BetterWorkplaceSectionPage topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route path={routes.searchPage}>
            {isAuthenticated ? (
              <SearchPage topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route exact path={routes.workingConnectFloors}>
            {isAuthenticated ? (
              <WorkingConnectFloors topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route exact path={routes.workingConnectFloorsVariants}>
            {isAuthenticated ? (
              <WorkingConnectFloors topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
          <Route exact path={routes.workingConnectFloorsVariantsWithDir}>
            {isAuthenticated ? (
              <WorkingConnectFloors topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>

          <Route exact path={routes.textPages}>
            {isAuthenticated ? (
              <TextPage topMenu={data} />
            ) : (
              <Redirect to={routes.homePage} />
            )}
          </Route>
        </Switch>
      )}
    </Router>
  );
};

export default App;
