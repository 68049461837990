import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";

const LoginContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default LoginContainer;

const Wrapper = styled.div({
  backgroundColor: colors.bgColor,
  padding: "45px",
  maxWidth: "360px",
  borderRadius: "7px",
  display: "flex",
  flexDirection: "column",
  margin: "60px 0 20px 0",
});
