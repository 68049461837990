import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";

// Import Components
import Layout from "../components/Layout";
import GeneralArrangementTab from "./components/GeneralArrangementTab";
import FurnitureTab from "./components/FurnitureTab";
import FinishesTab from "./components/FinishesTab";
import PlanPackTab from "./components/PlanPackTab";
import ElevationsTab from "./components/ElevationsTab";
import DetailsTab from "./components/DetailsTab";
import GuidesTab from "./components/GuidesTab";
import SubtabBar from "./components/SubtabBar";
import View360Tab from "./components/View360Tab";
import DownloadsTab from "./components/DownloadsTab";
import RDSTab from "./components/RDSTab";
import PhotosTab from "./components/PhotosTab";

// Import utils
import { useFetch, useRecentlySpaces } from "../utils/hooks";
import config from "../utils/config";

// Bootstrap components
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
// import LocalStorageUtil from "../utils/localStorage";
import { recentlyViewed } from "../utils/recentlyViewed";

const WorkingConnectFloors = ({ topMenu }) => {
  const { pathname } = useLocation();
  const { slug, type, size, specific } = useParams();
  const { loading, data, setLoading } = useFetch(`${config.strapiUrl}/spaces/${slug}`);
  const { data: sizes } = useFetch(`${config.strapiUrl}/sizes`);
  const { data: types } = useFetch(`${config.strapiUrl}/space-types`);
  const { userData } = useRecentlySpaces();
const [reloadFurniture,setReloadFurniture]=useState(false);
  const jwt = window.sessionStorage.getItem("jwt") || null;
  const uid = window.sessionStorage.getItem("uid") || null;

  useEffect(() => {
    const updateUser = async () => {
      const newUser = userData || [];

      if (userData && userData?.length === 5) {
        newUser?.shift();
      }

      if (!newUser.find((e) => e.space === slug)) {
        if (newUser.length === 0) {
          newUser?.push({ space: slug });
        } else {
          newUser?.push({
            // id: newUser[newUser?.length - 1].id + 1,
            space: slug,
          });
        }
      }

      await fetch(`${config.strapiUrl}/users/${uid}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          recently_viewed_spaces: newUser,
        }),
      });
    };

    updateUser();
  }, [jwt, slug, uid, userData]);

  useEffect(() => {
    if (!loading) {
      recentlyViewed(pathname, data, uid);
      setLoading(false);
    }
  }, [loading])

  const spaceTabs = [];
  const spaceTypes = [];
  const spaceSize = [];
  const typeLinks = [];
  const sizeLinks = [];
  const currentSpecific = specific || "general-arrangement";


  data?.space_variants?.map((item) => {
    if (!spaceTypes.find((e) => e === item.type)) spaceTypes.push(item.type);

    return true;
  });

  const currentType = type || types?.find((e) => e.id === spaceTypes[0])?.Label;
  const cuttentTypeId =
    types?.find((e) => e.Label === type)?.id ||
    types?.find((e) => e.id === spaceTypes[0])?.id;


  data?.space_variants?.map((item) => {
    if (item.type === cuttentTypeId && !spaceSize.find((e) => e === item.size))
      spaceSize.push(item.size);

    return spaceSize;
  });

  const currentSize = size || sizes?.find((e) => e.id === spaceSize[0])?.Label;

  const space_variants_id = data?.space_variants && data?.space_variants?.find((item) => item.type === cuttentTypeId && item.size === sizes?.find((e) => e.Label === currentSize)?.id);

  data?.space_variants?.map((item) => {
    if (!typeLinks.find((e) => e === item.type)) typeLinks.push(item.type);
    return typeLinks;
  });

  data?.space_variants?.map((item) => {
    if (item.type === cuttentTypeId && !sizeLinks.find((e) => e === item.size))
      sizeLinks.push(item.size);

    return sizeLinks;
  });

  const mainPic = data?.space_variants?.find(
    (e) =>
      e.type === types?.find((e) => e.Label === currentType)?.id &&
      e.size === sizes?.find((e) => e.Label === currentSize)?.id
  )?.main_pic;

  data?.space_variants
    .find(
      (e) =>
        e.type === types?.find((e) => e.Label === currentType)?.id &&
        e.size === sizes?.find((e) => e.Label === currentSize)?.id
    )
    ?.Tabs.map((tab) => {
      if (!spaceTabs.find((e) => e.__component === tab.__component))
        spaceTabs.push(tab);
      return spaceTabs;
    });

  let subtabData = data?.space_variants?.find(
    (e) =>
      e.type === types?.find((e) => e.Label === currentType)?.id &&
      e.size === sizes?.find((e) => e.Label === currentSize)?.id
  )
    ?.Tabs.find(
      (e) => e.__component === `spaces-components.${currentSpecific}`
    );
  if (subtabData) {
    subtabData.space_variant_id = space_variants_id ? space_variants_id?.id : 0;
    subtabData.space_type_id = cuttentTypeId ? cuttentTypeId : 0;
  }

  let rdsDownload = [];
  if (data?.space_variants) {
    rdsDownload = data?.space_variants && data?.space_variants?.find((e) =>
      e.type === types?.find((e) => e.Label === currentType)?.id &&
      e.size === sizes?.find((e) => e.Label === currentSize)?.id
    )
      ?.Tabs?.find((e) => e.__component === `spaces-components.rds`)?.RDS_download;
  }


  const renderTab = (tab) => {
    switch (tab) {
      case "general-arrangement":
        return (
          <GeneralArrangementTab
            subtabData={subtabData}
            name={data.Name}
            mainPic={mainPic}
            currentType={currentType}
            currentSize={currentSize}
            slug={slug}
          />
        );
      case "furniture":
        return <FurnitureTab name={data?.Name} furnitureData={subtabData} furnitures={subtabData?.furnitures} setReloadFurniture={setReloadFurniture}/>;
      case "finishes":
        return <FinishesTab data={subtabData} name={data?.Name} />;
      case "plans-pack":
        return <PlanPackTab data={subtabData} name={data?.Name} />;
      case "elevations":
        return <ElevationsTab data={subtabData} name={data?.Name} />;
      case "details":
        return <DetailsTab data={subtabData} name={data?.Name} />;
      case "guides":
        return <GuidesTab data={subtabData} name={data?.Name} />;
      case "360-view":
        return <View360Tab data={subtabData} name={data?.Name} />;
      case "downloads":
        return (
          <DownloadsTab data={subtabData} name={data?.Name} rds={rdsDownload} />
        );
      case "rds":
        return <RDSTab data={subtabData} name={data?.Name} />;
      case "photos":
        return <PhotosTab data={subtabData} name={data?.Name} />;

      default:
        return (
          <GeneralArrangementTab
            subtabData={subtabData}
            name={data.Name}
            mainPic={data.Main_pic}
            currentType={currentType}
            currentSize={currentSize}
            slug={slug}
          />
        );
    }
  };

  return (
    <Layout topMenu={topMenu} spacePage={true}>
      {loading ? (
        "Loading..."
      ) : (
        <Tab.Container defaultActiveKey="general-arrangement">
          <SubtabBar
            name={data?.Name}
            spaceType={currentType}
            spaceSize={currentSize}
            typeLinks={typeLinks}
            sizeLinks={sizeLinks}
            slug={data?.slug}
            specific={currentSpecific}
            spaceTabs={spaceTabs}
            sizes={sizes}
            types={types}
          />
          <Row>
            <Container
              fluid={currentSpecific === "guides" ? true : false}
              className="mt-5"
            >
              {renderTab(currentSpecific)}
            </Container>
          </Row>
        </Tab.Container>
      )}
    </Layout>
  );
};

export default WorkingConnectFloors;
