class LocalStorageUtil {
    constructor(name) {
        this.name = name;
    }

    localStorageHasItem = () => {
        return localStorage.getItem(this.name) !== null;
    };

    setToLocalStorage = (data) => {
        return localStorage.setItem(this.name, JSON.stringify(data));
    };

    getFromLocalStorage = () => {
        if (this.localStorageHasItem()) {
            return JSON.parse(localStorage.getItem(this.name));
        }
        return null;
    };

    removeFromLocalStorage = () => {
        if (this.localStorageHasItem()) {
            localStorage.removeItem(this.name);
        }
    };
}

export default LocalStorageUtil;
