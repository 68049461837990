import React from "react";

//  Import bootstrap
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";

import { Pannellum } from "pannellum-react";
import config from "../../utils/config";

const View360Tab = ({ data }) => {
  return (
    <Row>
        <Container>
            <Col className="mb-5">
              {data?.view_image && (
                <Pannellum
                  width="100%"
                  height="600px"
                  image={`${config.strapiUrl}/${data?.view_image.url}`}
                  pitch={10}
                  yaw={180}
                  hfov={110}
                  autoLoad
                />
              )}
            </Col>
        </Container>
    </Row>
  );
};

export default View360Tab;
