import styled from "styled-components";
import {colors} from "../../utils/colors";


export const RecentlyViewedSpacesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
`;

export const RecentlyViewedSpacesItem = styled.div`
    width: calc(100% / 4);
    padding: 15px;
    @media (max-width: 767px) {
      width: calc(100% / 2);
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;

export const NoResults = styled.p`
    font-size: 16px;
    color: ${colors.textColor};
    text-align: center;
    width: 100%;
    display: inline-block;
`;