import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

// import compnents
import Layout from "../components/Layout";
import Preloader from "../components/Preloader";

// Import utils
import { useFetchTextPage } from "../utils/hooks";
import config from "../utils/config";
import { colors } from "../utils/colors";

// Bootstrap components
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import { SRLWrapper } from "simple-react-lightbox";
import { Row, Col } from "react-bootstrap";
import { imageCaption } from "../utils/typography";
import {recentlyViewed} from "../utils/recentlyViewed";

import Input from "../components/forms/Input";
import Button from "../components/forms/Button";

const lightboxOptions = {
  buttons: {
    showDownloadButton: false
  }
};

const TextPage = ({ topMenu }) => {
  const [tabId, setTabId] = useState("");
  const { slug, tab } = useParams();
  const { loading, data } = useFetchTextPage(slug);

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const uid = window.sessionStorage.getItem("uid") || null;

  useEffect(() => {
    if (data?.text_page_tabs?.length) {
      setTabId(`tab${tab || data.text_page_tabs[0].id}`);
    }
  }, [data, tab]);

  const currentTab = data?.text_page_tabs?.find(
    (e) => e.id === +tabId.split("tab")[1]
  );

  let pageType = data?.Left_column && data?.Right_column ? "2col" : "tp";

  useEffect(()=>{
    if(!loading) {
      recentlyViewed(null, data, uid);

    }
  },[loading])



  const TextPageTabs = () => {
    return slug === "undefined" ? (
      <Redirect to="/" />
    ) : loading ? (
      <Preloader />
    ) : pageType === "tp" ? (
      <TabsWrapper>
        <div>
          {data?.text_page_tabs.length > 1 ? (
            <TabHeader>
              <Container>
                <Nav>
                  {data?.text_page_tabs.map((item, index) => (
                    <Nav.Item className="pt-3" key={item.id}>
                      <LinkStyled
                        className="cdg-nav-item"
                        eventKey={`tab${item.id}`}
                        onClick={() => setTabId(`tab${item.id}`)}
                        isactive={tabId === `tab${item.id}` ? "true" : "false"}
                      >
                        <span>{index + 1} </span>
                        {item.label}
                      </LinkStyled>
                    </Nav.Item>
                  ))}
                </Nav>
              </Container>
            </TabHeader>
          ) : null}
        </div>
        <div>
          {currentTab?.text_sections.length > 1 ? (
            <Container>
              <SectionBar className="cdg-section-bar">
                {currentTab.text_sections.map((section, index) => (
                  <p key={`p${index}`}>
                    <SectionLink href={`#section${section.id}`}>
                      {section.section_title}
                      {index + 1 !== currentTab.text_sections.length ? (
                        <span>|</span>
                      ) : null}
                    </SectionLink>
                  </p>
                ))}
              </SectionBar>
            </Container>
          ) : (
            <div></div>
          )}
          {currentTab.text_sections.map((section) => (
            <SectionBody
              id={`section${section.id}`}
              key={`section${section.id}`}
              className="cdg-section-body"
            >
              <Container>
                <SectionTitle>{section.section_title}</SectionTitle>
                {section?.Single_column ? (
                  <SectionTextSingleColumn
                    className={"text-single-column"}
                    dangerouslySetInnerHTML={{
                      __html: section?.section_content?.replaceAll(
                        `<img src="/`,
                        `<img class="img-fluid" src="${config.strapiUrl}/`
                      ),
                    }}
                  />
                ) : (
                  <SectionText
                    dangerouslySetInnerHTML={{
                      __html: section?.section_content?.replaceAll(
                        `<img src="/`,
                        `<img class="img-fluid" src="${config.strapiUrl}/`
                      ),
                    }}
                  />
                )}
                {section?.Tab_images.length ? (
                  <SRLWrapper options={lightboxOptions}>
                    <Row>
                      {section?.Tab_images?.map((item) => (
                        <Col xs={section?.Single_column ? 12 : 4}>
                          <div key={item.id}>
                            <ImageStyled
                              src={config.strapiUrl + item.url}
                              alt={item.alternativeText}
                            />
                            <p style={imageCaption}>{item.caption}</p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </SRLWrapper>
                ) : ''}
              </Container>
            </SectionBody>
          ))}
        </div>
      </TabsWrapper>
    ) : (
      <TabsWrapper>
        <Container style={{ padding: "30px 0" }}>
          
          <SectionTitle>{data.Page_title}</SectionTitle>
          <Section2Cols>
            <div
              dangerouslySetInnerHTML={{
                __html: data.Left_column?.replaceAll(
                  `<img src="/`,
                  `<img class="img-fluid" src="${config.strapiUrl}/`
                ),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: data.Right_column?.replaceAll(
                  `<img src="/`,
                  `<img class="img-fluid" src="${config.strapiUrl}/`
                ),
              }}
            />
          </Section2Cols>
        </Container>
      </TabsWrapper>
    );
  };
  return <Layout topMenu={topMenu} textPageTabs={TextPageTabs} />;
};

export default TextPage;

const Section2Cols = styled("div")({
  "& div": {
    width: "50%",
    "&:nth-child(1)": {
      paddingRight: "8px",
    },
    "&:nth-child(2)": {
      paddingLeft: "8px",
    },
  },
  display: "flex",

  "@media screen  and (max-width: 992px)": {
    display: "block",
  },
});

const TabsWrapper = styled("div")({
  margin: "0 auto",
  width: "100%",
});

const SectionBar = styled("div")({
  display: "flex",
  marginTop: "30px",
});

const Seperator = styled("div")({
  height: "60px",
});

const SectionBody = styled("div")({
  "&:nth-child(odd)": {
    backgroundColor: "#eee",
  },
  padding: "30px 0",
  marginBottom: "15px",
  backgroundColor: "#fff",
});

const SectionTitle = styled("p")({
  color: colors.primary,
  fontSize: "32px",
  fontWeight: 500,
});

const SectionText = styled("div")({
  columnCount: 3,
  color: colors.textColor,
  fontSize: "16px",
  columnGap: "55px",

  "@media screen  and (max-width: 992px)": {
    columnCount: 1,
  },
});

const SectionTextSingleColumn = styled("div")({
  columnCount: 1,
  color: colors.textColor,
  fontSize: "16px",

  "@media screen  and (max-width: 992px)": {
    columnCount: 1,
  },
});

const TabHeader = styled("div")({
  width: "100%",
  backgroundColor: "#eee",
});

const LinkStyled = styled(Nav.Link)({
  backgroundColor: (props) =>
    props.isactive === "true" ? "white" : "transparent",
  padding: "15px 45px",
  borderRadius: "7px 7px 0 0 ",
  color: colors.textColor,
  fontSize: 16,

  span: {
    color: colors.primary,
    fontWeight: 600,
  },
});

const SectionLink = styled("a")({
  span: {
    margin: "0 5px",
    color: "#000",
    fontWeight: 400,
  },
  "&:hover": {
    color: colors.secondary,
  },
  color: colors.primary,
  fontSize: 14,
  fontWeight: 600,
  textDecoration: "none",
});

const ImageStyled = styled("img")({
  cursor: "pointer",
  width: "100%",
  height: "300px",
  objectFit: "cover",
  marginBottom: "15px",
  marginTop: "15px",
});
