import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Bootstrap Components
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

// Import components
import SpaceWrapper from "./SpaceWrapper";

// Import utils
import { useFetch, useRecentlySpaces } from "../../utils/hooks";
import LocalStorageUtil from "../../utils/localStorage";
import config from "../../utils/config";
import { heading3 } from "../../utils/typography";
import { LinkStyled, QuickLinksContainer, QuickLinksWrapper } from "../Home";
import { NoResults, RecentlyViewedSpacesItem, RecentlyViewedSpacesWrapper } from "./RecentlyViewedSpaces.styled";

const RecentlyViewedSpaces = () => {
  const { userData, userLoading } = useRecentlySpaces();
  const localStorageRecentlyViewedSpaces = new LocalStorageUtil('recentlyViewedSpaces');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const jwt = window.sessionStorage.getItem("jwt") || null;

  useEffect(() => {
    if (localStorageRecentlyViewedSpaces.getFromLocalStorage()) {
      const recentlyViewedSpaces = localStorageRecentlyViewedSpaces.getFromLocalStorage().recentlyViewedSpaces;
      setLoading(true);
      const promises = [];

      recentlyViewedSpaces.map(async ({ slug, mainRoute }) => {
        const hasMainRoute = mainRoute ? 'spaces/' : 'text-pages/';

        promises.push(fetch(`${config.strapiUrl}/${hasMainRoute}${slug}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }))
      });
      Promise.all(promises).then((results) => {
        if (results) {
          console.log('results: ', results);
          const resultsArray = [];
          results.map((item) =>
            resultsArray.push(item.json())
          );


          Promise.all(resultsArray).then((res) => {
            res.map((itemIndex) => {
              const index = recentlyViewedSpaces.findIndex((item) => (item.mainRoute && item.id === itemIndex.id));

              if (index !== -1) {
                res[index].mainRoute = recentlyViewedSpaces[index].mainRoute;
              }
            })

            setState(res);
            setLoading(false)
          })
        }

      });
    }
  }, []);

  return (
    <RowStyled>
      {(userLoading || loading)
        ? "Loading..."
        : (
          <RecentlyViewedSpacesWrapper>
            {state.length > 0 ? state.map((item, index) =>
              index <= 3 && (
                <RecentlyViewedSpacesItem key={index}>
                  <LinkStyled key={item.id} to={`${item.mainRoute || ''}/${item.slug}`}>
                    <QuickLinksContainer>
                      {item.Main_pic && (
                        <img
                          src={`${config.strapiUrl}${item.Main_pic.url}`}
                          alt={item.Main_pic.alternativeText}
                          className="img-fluid search-img"
                        />
                      )}
                      <h3 style={heading3}>{item?.Title || item.top_menu_label}</h3>
                    </QuickLinksContainer>
                  </LinkStyled>
                </RecentlyViewedSpacesItem>
              )
            ) : (
              <NoResults>No results</NoResults>
            )}
          </RecentlyViewedSpacesWrapper>
        )}
    </RowStyled>
  );
};

export default RecentlyViewedSpaces;

const RowStyled = styled(Row)({
  justifyContent: "center",
});
