import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

// Import Components
import Input from "./forms/Input";
import Button from "../components/forms/Button";
import Search from "./icons/Search";

const SearchBar = ({ center, whiteBorder }) => {
  const { search } = useLocation();

  const word = search.split("?s=")[1];

  const [searchWord, setSearchWord] = useState(word || "");

  return (
    <Wrapper
      center={center}
      action="/search"
      className="text-center d-block d-md-flex"
    >
      <Input
        name="s"
        icon={Search}
        value={searchWord.replace('+', ' ')}
        onChange={(e) => setSearchWord(e)}
      />
      <Button padding="8px 20px" whiteBorder={whiteBorder}>
        Search
      </Button>
    </Wrapper>
  );
};

export default SearchBar;

const Wrapper = styled.form({
  display: "flex",
  justifyContent: (props) => (props.center ? "center" : "flex-start"),
});
