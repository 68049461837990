import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Bootstrap component
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/Modal";

import Button from "./forms/Button";
import Hamburger from "./icons/Hamburger";

// import utils
import { colors } from "../utils/colors";
import { menuSection } from "../utils/typography";

const MobileTopMenu = ({ activeItem, topMenu }) => {
  const [moved, setMoved] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (moved) {
    setTimeout(() => {
      setMoved(false);
    }, 100);
  }
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <Hamburger />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NavStyled>
            {topMenu?.Top_level_items?.map((item) => (
              <div key={item.id} className="mb-3">
                {!moved && item?.sections?.length ? (
                  <Dropdown>
                    <ToggleStyled activeitem={`${activeItem === item.slug}`}>
                      {item.Label}
                    </ToggleStyled>
                    <MenuStyled>
                      {item.sections.map((section) => (
                        <Col key={section.label}>
                          {section.text_page && section.spaces.length === 0 ? (
                            <Link
                              style={menuSection}
                              to={`/${section.text_page.slug}`}
                              onClick={() => setMoved(true)}
                            >
                              {section.label}
                            </Link>
                          ) : (
                            <p style={menuSection}>{section.label}</p>
                          )}
                          {section.spaces &&
                            section.spaces.map((menuItem) => {
                              return (
                                <Item
                                  key={menuItem.slug}
                                  to={`/${item.slug}/${menuItem.slug}`}
                                  onClick={() => setMoved(true)}
                                >
                                  {menuItem.top_menu_label}
                                </Item>
                              );
                            })}
                        </Col>
                      ))}
                    </MenuStyled>
                  </Dropdown>
                ) : (
                  <TopLevelItem
                    to={`/${item?.text_page?.slug}`}
                    activeitem={`${activeItem === item.slug}`}
                  >
                    {item.Label}
                  </TopLevelItem>
                )}
              </div>
            ))}
          </NavStyled>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MobileTopMenu;

const TopLevelItem = styled(Link)({
  "&:hover": {
    color: "#000",
    backgroundColor: "#fff",
  },
  backgroundColor: (props) =>
    props.activeitem === "true" ? "#fff!important" : colors.primary,
  color: (props) => (props.activeitem === "true" ? "#000!important" : "#fff"),
  height: "62px",
  fontSize: "16px",
  whiteSpace: "break-spaces",
  border: `1px solid ${colors.primary}`,
  textAlign: "center",
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.25em",
  padding: "0 6px",
  transition:
    "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  width: "100%",
});

const NavStyled = styled(Nav)({
  alignItems: "center",
  height: "100%",
  justifyContent: "flex-end",
  flexWrap: "nowrap",
  display: "block",
});

const Item = styled(Link)({
  fontSize: "14px",
  color: colors.textColor,
  textDecoration: "none",
  display: "block",
});

const ToggleStyled = styled(Dropdown.Toggle)({
  "&::after": {
    display: "none",
    borderColor: "transparent",
  },
  "&:hover, &:focus, &:active": {
    backgroundColor: "#fff!important",
    color: "#000!important",
    boxShadow: "none!important",
    border: `1px solid ${colors.primary}!improtant`,
    outilne: "none!important",
  },
  "&[aria-expanded=true]": {
    boxShadow: "none",
    borderColor: `${colors.primary}!important`,
  },

  backgroundColor: (props) =>
    props.activeitem === "true" ? "#fff!important" : colors.primary,
  color: (props) => (props.activeitem === "true" ? "#000!important" : "#fff"),
  fontSize: "16px",
  whiteSpace: "break-spaces",
  border: `1px solid ${colors.primary}`,
  width: "100%",
  height: "62px",
});

const MenuStyled = styled(Dropdown.Menu)({
  width: "800px",
  top: "-5px!important",
  borderRadius: "0 0 5px 5px",
  border: `1px solid ${colors.primary}`,
  borderTop: "none",
  padding: "30px",
});
