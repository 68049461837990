import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/forms/Button";

import { colors } from "../../utils/colors";
import { routes } from "../../utils/routes";

const Footer = () => {
  return (
    <Wrapper>
    
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div({
  backgroundColor: colors.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  paddingTop: "30px",
  paddingBottom: "15px",
});
