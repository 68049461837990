import React from "react";

const Logo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 126 21.5"
      style={{ enableBackground: "new 0 0 126 21.5", width: "160px" }}
    >
      <path
        style={{ fill: "#FFFFFF" }}
        d="M10.8,3.7c-0.6-0.2-1.4-0.3-2.1-0.3c-1,0-1.8,0.2-2.4,0.6S5.2,5,4.9,5.7C4.5,6.4,4.3,7.2,4.2,8.1
	C4.1,9,4,9.9,4,10.9c0,1,0.1,1.9,0.2,2.8c0.1,0.9,0.4,1.7,0.7,2.3c0.3,0.7,0.8,1.2,1.4,1.6s1.4,0.6,2.3,0.6c0.8,0,1.5-0.1,2.1-0.3
	s1.2-0.4,1.9-0.7v3.5c-0.6,0.3-1.3,0.5-2.1,0.6s-1.6,0.2-2.5,0.2c-1.6,0-2.9-0.3-3.9-0.8c-1-0.6-1.9-1.3-2.5-2.3
	c-0.6-1-1.1-2.1-1.3-3.4S0,12.3,0,10.8C0,9.5,0.1,8.3,0.4,7s0.7-2.4,1.3-3.5S3.2,1.6,4.3,1s2.5-1,4.2-1c0.7,0,1.4,0.1,2.2,0.2
	c0.8,0.1,1.4,0.3,2,0.6v3.5C12.1,4.1,11.5,3.8,10.8,3.7z M29.8,14.9c-0.4,1.3-0.9,2.4-1.7,3.3c-0.7,0.9-1.7,1.7-2.8,2.2
	c-1.1,0.5-2.5,0.8-4.1,0.8h-5.6V0.3H21c1.6,0,3.1,0.3,4.2,0.8c1.2,0.5,2.1,1.2,2.9,2.1c0.8,0.9,1.3,2,1.7,3.3
	c0.4,1.3,0.5,2.7,0.5,4.3C30.3,12.3,30.1,13.6,29.8,14.9z M26.1,8.2c-0.1-0.9-0.4-1.6-0.7-2.3c-0.3-0.7-0.8-1.3-1.4-1.7
	c-0.6-0.4-1.4-0.6-2.3-0.6h-2.2V18h2.2c0.9,0,1.6-0.2,2.2-0.6c0.6-0.4,1.1-1,1.4-1.7c0.3-0.7,0.6-1.5,0.7-2.3
	c0.1-0.9,0.2-1.7,0.2-2.6C26.3,9.9,26.2,9.1,26.1,8.2z M43.9,3.7c-0.7-0.2-1.6-0.3-2.5-0.3c-0.9,0-1.7,0.2-2.2,0.6
	c-0.6,0.4-1,1-1.4,1.6C37.4,6.3,37.2,7.1,37,8c-0.1,0.9-0.2,1.8-0.2,2.8c0,0.8,0.1,1.7,0.2,2.6c0.1,0.9,0.3,1.7,0.7,2.4
	c0.3,0.7,0.8,1.3,1.3,1.8s1.3,0.7,2.2,0.7c0.5,0,1,0,1.3-0.1c0.3-0.1,0.7-0.2,1.1-0.3v-7.1h3.8V20c-0.8,0.5-1.8,0.9-2.9,1.2
	c-1.1,0.3-2.2,0.4-3.3,0.4c-1.6,0-2.9-0.3-4-0.8c-1.1-0.6-1.9-1.3-2.6-2.3c-0.6-1-1.1-2.1-1.4-3.4s-0.4-2.7-0.4-4.2
	c0-1.4,0.1-2.7,0.4-4s0.7-2.4,1.4-3.4c0.7-1,1.5-1.8,2.6-2.4S39.5,0,41.1,0c1,0,1.9,0.1,2.8,0.3C44.7,0.5,45.4,0.7,46,1v3.6
	C45.3,4.3,44.6,4,43.9,3.7z M67.8,12.2c-1.4,1.3-3.3,1.9-5.7,1.9h-1.6v6.9h-3.9V0.2h5.9c1,0,2,0.1,2.9,0.4s1.7,0.7,2.4,1.3
	c0.7,0.6,1.2,1.3,1.6,2.1c0.4,0.8,0.6,1.8,0.6,2.9C69.8,9.2,69.1,11,67.8,12.2z M64.7,4.3c-0.7-0.6-1.8-0.9-3-0.9h-1.3v7.5h1.3
	c1.3,0,2.4-0.3,3.1-0.9c0.7-0.6,1.1-1.6,1.1-3C65.8,5.7,65.5,4.8,64.7,4.3z M83.4,16.8c-0.2,0.9-0.6,1.7-1.1,2.4
	c-0.5,0.7-1.2,1.2-2,1.6c-0.8,0.4-1.8,0.6-2.9,0.6c-1.1,0-2.1-0.2-2.9-0.6c-0.8-0.4-1.5-0.9-2-1.6c-0.5-0.7-0.9-1.5-1.1-2.4
	c-0.2-0.9-0.4-1.9-0.4-2.9s0.1-2,0.3-2.9s0.6-1.7,1.1-2.4c0.5-0.7,1.2-1.2,2-1.6c0.8-0.4,1.8-0.6,3-0.6c1.2,0,2.1,0.2,3,0.6
	c0.8,0.4,1.5,1,2,1.6c0.5,0.7,0.9,1.5,1.1,2.4c0.2,0.9,0.3,1.9,0.3,2.9C83.7,15,83.6,15.9,83.4,16.8z M79.9,12.2
	c-0.1-0.5-0.2-1-0.4-1.4c-0.2-0.4-0.5-0.7-0.8-1c-0.3-0.3-0.8-0.4-1.4-0.4c-0.6,0-1,0.1-1.4,0.4c-0.4,0.2-0.6,0.6-0.8,1
	c-0.2,0.4-0.3,0.9-0.4,1.4c-0.1,0.5-0.1,1.1-0.1,1.7c0,0.6,0,1.1,0.1,1.7c0.1,0.5,0.2,1,0.4,1.4c0.2,0.4,0.5,0.8,0.8,1
	c0.3,0.3,0.8,0.4,1.4,0.4s1.1-0.1,1.4-0.4c0.4-0.3,0.6-0.6,0.8-1s0.3-0.9,0.4-1.4c0.1-0.5,0.1-1.1,0.1-1.7
	C80,13.3,79.9,12.7,79.9,12.2z M91.6,7.1c0.6-0.4,1.2-0.6,1.9-0.6c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0.1,0.7,0.2v3.7
	c-0.3-0.1-0.6-0.1-0.8-0.1c-0.2,0-0.4,0-0.6,0c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1.1,0.5-1.6,0.9v9.6h-3.7V6.7h3.1L90,9.1
	C90.4,8.2,91,7.5,91.6,7.1z M106.1,6.7v3h-3.7v6.7c0,0.8,0.2,1.3,0.4,1.6s0.7,0.4,1.4,0.4c0.8,0,1.6-0.1,2.3-0.4v3
	c-0.5,0.1-1,0.2-1.5,0.3c-0.5,0.1-1.1,0.1-1.8,0.1c-1.4,0-2.6-0.4-3.3-1.2c-0.8-0.8-1.1-1.9-1.1-3.3V9.7H96v-3h2.8V2.9h3.7v3.9
	H106.1z M115.6,19.8c-0.4,0.4-0.8,0.7-1.4,1.1c-0.5,0.3-1.2,0.5-2.1,0.5c-1.3,0-2.4-0.4-3.2-1.2c-0.8-0.8-1.1-1.9-1.1-3.4
	c0-0.9,0.2-1.6,0.5-2.2c0.3-0.6,0.7-1.1,1.3-1.5c0.5-0.4,1.2-0.6,1.9-0.8c0.7-0.2,1.5-0.2,2.3-0.2h1.6v-0.8c0-0.6-0.2-1.1-0.6-1.4
	c-0.4-0.3-0.9-0.5-1.6-0.5c-0.6,0-1.3,0.1-2,0.2c-0.7,0.1-1.4,0.3-2,0.5V7c0.3-0.1,0.6-0.2,1-0.2c0.4-0.1,0.8-0.2,1.2-0.2
	c0.4-0.1,0.8-0.1,1.3-0.2c0.4,0,0.8-0.1,1.2-0.1c1.8,0,3.1,0.4,4,1.3c0.8,0.9,1.3,2.2,1.3,3.9v9.5h-3.1L115.6,19.8z M114,14.4
	c-0.8,0-1.4,0.2-1.8,0.5c-0.5,0.4-0.7,0.9-0.7,1.6c0,0.6,0.1,1.1,0.4,1.5c0.3,0.4,0.7,0.6,1.3,0.6c0.4,0,0.8-0.1,1.1-0.2
	c0.3-0.2,0.6-0.3,0.9-0.5v-3.6L114,14.4L114,14.4z M122.3,21.1V0.2h3.7v20.9H122.3z"
      />
    </svg>
  );
};

export default Logo;
