import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

// Import utils
import { colors } from "../utils/colors";
import { routes } from "../utils/routes";

// Bootstrap component
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Import Components
import Logo from "../Auth/components/icons/Logo";
import TopMenu from "./TopMenu";
import MobileTopMenu from "./MobileTopMenu";

const Header = ({ topMenu }) => {
  const location = useLocation();
  const activeItem = location.pathname.split("/")[1];

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col xs={10} lg={2} className="d-flex align-items-center ps-2 pt-2 pt-lg-3">
            <Link to={routes.homePage}>
              <Logo />
            </Link>
          </Col>
          <Col xs={2} lg={10} className="pe-2">
            <div className="d-none d-lg-block justify-content-md-end main-menu">
              <TopMenu activeItem={activeItem} topMenu={topMenu} />
            </div>
            <div className="d-block d-lg-none text-end">
              <MobileTopMenu topMenu={topMenu} />
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div({
  padding: "15px 0px",
  backgroundColor: colors.primary,
  width: "100%",
});
