import React from "react";
import styled from "styled-components";

import { colors } from "../../utils/colors";
import Logo from "./icons/Logo";

const Header = () => {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div({
  minHeight: "120px",
  backgroundColor: colors.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});
