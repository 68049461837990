import React from "react";
import { useParams } from "react-router";

import Layout from "../../components/Layout";

const BetterWorkplaceSectionPage = ({ topMenu }) => {
  const { section } = useParams();
  return (
    <Layout topMenu={topMenu}>
      <p>{section} page</p>
    </Layout>
  );
};

export default BetterWorkplaceSectionPage;
